import React, { useState } from 'react';
import {
  Collapse,
  ListItem,
  ListItemText,
  ListItemIcon,
  makeStyles,
  useMediaQuery,
  useTheme,
  Theme,
} from '@material-ui/core';
import { ExpandLessSharp, ExpandMoreSharp } from '@material-ui/icons';

const useListItemStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  gutters: {
    paddingLeft: 0,
  },
}));

const useListItemTextStyles = makeStyles((theme) => ({
  primary: {
    marginRight: theme.spacing(2),
  },
}));

const useCollapseStyles = makeStyles<
  Theme,
  { maxHeight: number | string; overflow?: boolean }
>({
  wrapper: {
    overflow: ({ overflow }) => (overflow ? 'visible' : 'auto'),
  },
  wrapperInner: {
    padding: 0,
    maxHeight: ({ maxHeight }) => maxHeight,
  },
});

const useListItemIconStyles = makeStyles((theme) => ({
  root: {
    minWidth: theme.spacing(6),
    '&::before': {
      content: "''",
      position: 'absolute',
      display: 'block',
      width: 10,
      height: 10,
      backgroundColor: theme.palette.primary.main,
      transform: 'translateY(-50%)',
    },
  },
}));

export interface AppListItemProps {
  title: string;
  secondary?: string;
  maxHeight?: number;
  overflow?: boolean;
  endAdornment?: JSX.Element;
}

const AppListItem: React.FC<AppListItemProps> = ({
  title,
  secondary,
  maxHeight = 'none',
  overflow,
  endAdornment,
  children,
}) => {
  const [open, setOpen] = useState(true);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const listItemStyles = useListItemStyles();
  const listItemIconStyles = useListItemIconStyles();
  const listItemTextStyles = useListItemTextStyles();
  const collapseStyles = useCollapseStyles({
    maxHeight: isDesktop ? maxHeight : 'none',
    overflow,
  });

  return (
    <>
      <ListItem
        button={!isDesktop || undefined}
        classes={listItemStyles}
        disableGutters={isDesktop}
        onClick={() => {
          if (!isDesktop) {
            setOpen((value) => !value);
          }
        }}
      >
        <ListItemIcon classes={listItemIconStyles} />
        <ListItemText
          classes={listItemTextStyles}
          primary={`${title} `}
          primaryTypographyProps={{
            component: 'h2',
            variant: 'h2',
            display: 'inline',
          }}
          secondary={secondary}
          secondaryTypographyProps={{
            component: 'p',
            variant: 'subtitle2',
            display: 'inline',
            noWrap: true,
          }}
        />
        {endAdornment && endAdornment}
        {!isDesktop && (open ? <ExpandLessSharp /> : <ExpandMoreSharp />)}
      </ListItem>
      <Collapse classes={collapseStyles} in={open || isDesktop}>
        {children}
      </Collapse>
    </>
  );
};

export default AppListItem;

import React, { useState } from 'react';
import { Box, Divider, Tabs, Tab, makeStyles } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';

import { OptionType, QueueItemType } from '../../types';
import Rounds from '../Rounds';
import AppListItem from '../AppListItem';
import CheckboxFormGroup from '../CheckboxFormGroup';
import Queue from '../Queue';

const useTabStyles = makeStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    minWidth: 0,
    minHeight: 0,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'transparent',
    borderBottomWidth: 0,
  },
  selected: {
    backgroundColor: theme.palette.background.default,
    borderColor: 'rgba(255, 255, 255, 0.25)',
  },
}));

const useTabsStyles = makeStyles({
  root: {
    minHeight: 0,
  },
  indicator: {
    display: 'none',
  },
});

export interface UpNextProps {
  channelId: string;
  items: QueueItemType[];
  currentId?: string;
  categoryOptions: OptionType[];
  categoryValue: OptionType[];
  round?: string;
  loading?: boolean;
  setRound: (value?: string) => void;
  setCategories: (value: OptionType[]) => void;
}

const UpNext: React.FC<UpNextProps> = ({
  loading,
  channelId,
  currentId,
  items = [],
  round,
  categoryOptions = [],
  categoryValue,
  setRound,
  setCategories,
}) => {
  const tabStyles = useTabStyles();
  const tabsStyles = useTabsStyles();
  const [tab, setTab] = useState('round');

  return (
    <AppListItem title="Up Next" overflow>
      <TabContext value={tab}>
        <Tabs
          classes={tabsStyles}
          value={tab}
          textColor="secondary"
          onChange={(_, value) => setTab(value)}
        >
          <Tab classes={tabStyles} label="Filter by round" value="round" />
          {categoryOptions.length > 1 && (
            <Tab
              classes={tabStyles}
              label="Filter by category"
              value="category"
            />
          )}
        </Tabs>
        <Divider style={{ marginTop: -1 }} />
        <TabPanel value="round">
          <Box paddingY={6}>
            <Rounds value={round} setRound={setRound} loading={loading} />
          </Box>
        </TabPanel>
        {categoryOptions.length > 1 && (
          <TabPanel value="category">
            <Box paddingY={4}>
              <CheckboxFormGroup
                options={categoryOptions}
                value={categoryValue}
                setValue={setCategories}
                disabled={loading}
              />
            </Box>
          </TabPanel>
        )}
      </TabContext>
      <Divider />
      <Box height={['auto', 'auto', 800]} overflow="auto">
        <Queue
          items={items}
          currentId={currentId}
          channelId={channelId}
          loading={loading}
          round={round}
        />
      </Box>
    </AppListItem>
  );
};

export default UpNext;

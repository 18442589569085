import React from 'react';
import { Box, Grid } from '@material-ui/core';

import { NBLA_PLACEHOLDER } from '../../constants';
import { MY_CHANNEL_HISTORY } from '../../constants/misc';
import { useGetHistoryQuery } from '../../store/apis/liniusMiddleware';
import AppListItem from '../AppListItem';
import VideoCard from '../VideoCard';

export interface HistoryProps {
  userId?: string;
}

const History: React.FC<HistoryProps> = ({ userId }) => {
  const { data = [] } = useGetHistoryQuery(userId, { skip: !userId });

  return (
    <AppListItem title={MY_CHANNEL_HISTORY}>
      <Box width="100%" paddingBottom={4} style={{ overflowX: 'scroll' }}>
        <Grid container wrap="nowrap">
          {data.map((item, index) => (
            <Grid key={index} item style={{ paddingLeft: index ? 16 : 0 }}>
              <VideoCard
                title={item.channel_name}
                image={item.thumbnail_url || NBLA_PLACEHOLDER}
                date={item.timestamp}
                href={`/channel/${item.channel_id}`}
                light
                contain
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </AppListItem>
  );
};

export default History;

import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, LinearProgress } from '@material-ui/core';

import { useGetChannelQuery } from '../../store/apis/liniusMiddleware';
import { selectUserId } from '../../store/slices/user';

const RenderPrivateRoute = ({
  hasData,
  isUnauthorized,
  children,
}: Record<string, boolean | React.ReactNode>) => {
  if (isUnauthorized) return <Redirect to="/noid" />;

  if (!hasData) return <Redirect to="/build-your-channel" />;

  return <>{children}</>;
};

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const userId = useSelector(selectUserId);
  const {
    data,
    isUninitialized,
    isLoading,
    error = {},
  } = useGetChannelQuery(userId, {
    skip: !userId,
  });

  const isUnauthorized =
    ('status' in error && error.status === 401) ||
    ('originalStatus' in error && error.originalStatus === 401);
  const hasData = !!data;

  return (
    <Route {...rest}>
      {isUninitialized || isLoading ? (
        <Box width="100%">
          <LinearProgress />
        </Box>
      ) : (
        <RenderPrivateRoute hasData={hasData} isUnauthorized={isUnauthorized}>
          {children}
        </RenderPrivateRoute>
      )}
    </Route>
  );
};

export default PrivateRoute;

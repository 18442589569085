import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useFormikContext } from 'formik';
import {
  Box,
  Button,
  Grid,
  Typography,
  Tab,
  CircularProgress,
} from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import {
  AddSharp,
  AddToQueueSharp,
  CheckCircleSharp,
  KeyboardArrowLeftSharp,
} from '@material-ui/icons';

import { IChannel, IPlayer } from '../../types';
import {
  CHOOSE_HERE_IF,
  BACK,
  CHANNEL_PREFERENCES,
} from '../../constants/misc';
import { actions, highlightTypes } from '../../constants';
import AppListItem from '../AppListItem';
import PlayerSelectList from '../PlayerSelectList';

export interface ThirdPageProps {
  setStep?: (value: number) => void;
}

const ThirdPage: React.FC<ThirdPageProps> = ({ setStep }) => {
  const history = useHistory();
  const [tab, setTab] = useState('0');
  const { values, setFieldValue, submitForm, isSubmitting, isValid } =
    useFormikContext<IChannel>();

  const updatePlayers = (teamId: number, players: IPlayer[]) => {
    const newTeams = values.teams.map((team) => {
      return team.teamId === teamId
        ? {
            ...team,
            players,
          }
        : team;
    });

    setFieldValue('teams', newTeams);
  };

  const updateTypes = (type: string) => {
    const newTypes = values.highlights.some((t) => t === type)
      ? values.highlights.filter((t) => t !== type)
      : [...values.highlights, type];

    setFieldValue('highlights', newTypes);
  };

  const onSubmit = async () => {
    await submitForm();

    history.push('/channel');
  };

  const isNoPlayersSelected = values.teams.every(
    (team) => team.players.length === 0
  );

  const isPlayerHighlightsOnlySelected =
    values.highlights.length === 1 &&
    values.highlights.includes('player_highlights');

  const isNoContentInChannel =
    isPlayerHighlightsOnlySelected && isNoPlayersSelected;

  return (
    <AppListItem title={CHANNEL_PREFERENCES}>
      <Box>
        <Box marginBottom={8} maxWidth={360}>
          <Typography>{CHOOSE_HERE_IF}</Typography>
        </Box>
        <Box marginY={8} maxWidth={480}>
          <Grid container spacing={4}>
            {highlightTypes.map(({ id, label }) => (
              <Grid item key={id}>
                <Button
                  color="primary"
                  variant={
                    values.highlights.includes(id) ? 'contained' : 'outlined'
                  }
                  size="large"
                  onClick={() => updateTypes(id)}
                  startIcon={
                    values.highlights.includes(id) ? (
                      <CheckCircleSharp />
                    ) : (
                      <AddSharp color="primary" />
                    )
                  }
                >
                  {label}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
        {values.highlights.includes('player_highlights') && (
          <TabContext value={tab}>
            <Box marginY={4}>
              <TabList
                indicatorColor="primary"
                onChange={(_, value) => setTab(value)}
              >
                {values.teams.map((team, index) => (
                  <Tab key={index} value={`${index}`} label={team.name} />
                ))}
              </TabList>
            </Box>
            <Box marginY={4} maxWidth={560}>
              {values.teams.map((team, index) => (
                <TabPanel key={index} value={`${index}`}>
                  <Box marginY={4} overflow="auto" maxHeight={480}>
                    <Box overflow="hidden">
                      <PlayerSelectList
                        actions={actions}
                        value={team.players}
                        teamId={team.teamId}
                        setValue={updatePlayers}
                      />
                    </Box>
                  </Box>
                </TabPanel>
              ))}
            </Box>
          </TabContext>
        )}
        <Box marginY={16} maxWidth={360}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                size="large"
                onClick={() => setStep && setStep(1)}
                startIcon={<KeyboardArrowLeftSharp />}
              >
                {BACK}
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                size="large"
                disabled={!isValid || isNoContentInChannel}
                onClick={() => onSubmit()}
                endIcon={
                  isSubmitting ? (
                    <CircularProgress
                      size={22}
                      thickness={6}
                      color="secondary"
                    />
                  ) : (
                    <AddToQueueSharp />
                  )
                }
              >
                Build Your Channel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </AppListItem>
  );
};

export default ThirdPage;

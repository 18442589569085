import React from 'react';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';

import Layout from './ContainerLayout';
import ProgressStepper from '../components/ProgressStepper';

interface FormLayoutProps {
  index: number;
}

const FormLayout: React.FC<FormLayoutProps> = ({ index, children }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      width="100%"
      minHeight="100vh"
      style={{
        backgroundImage: isTablet ? "url('images/court.jpg')" : 'none',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top right',
        backgroundSize: isDesktop ? 'auto 80vh' : '70vw auto',
      }}
    >
      <Layout>
        <Box width="100%" maxWidth={360} paddingTop={8}>
          <ProgressStepper current={index} total={3} />
        </Box>
        {children}
      </Layout>
    </Box>
  );
};

export default FormLayout;

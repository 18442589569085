import React from 'react';
import {
  Box,
  FormControlLabel,
  Typography,
  Checkbox,
  makeStyles,
} from '@material-ui/core';
import CheckboxFormGroup from '../CheckboxFormGroup';
import { IPlayer, OptionType } from '../../types';
import { selectedStateType } from './PlayerSelectList';

export interface PlayerSelectItemProps {
  player: IPlayer;
  selected: selectedStateType;
  actions: OptionType[];
  onUpdate: (value: IPlayer, selected: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '8px',
    padding: '16px',
    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.075)' },
  },
  labelRoot: {
    width: '100%',
    marginBottom: '16px',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      maxWidth: 60,
      margin: `0 ${theme.spacing(6)}px 0 ${theme.spacing(3)}px`,
    },
  },

  actions: {
    fontSize: '0.9em',
  },
}));

const PlayerSelectItem: React.FC<PlayerSelectItemProps> = ({
  player,
  selected,
  actions,
  onUpdate,
}) => {
  const styles = useStyles();

  const handleUpdate = (values: OptionType[]) => {
    onUpdate({ ...player, shot: values.map(({ value }) => value) }, true);
  };

  const handleSelect = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.checked) {
      onUpdate(
        { ...player, shot: actions.map(({ value }) => `${value}`) },
        true
      );
    } else if (!target.checked) {
      onUpdate(player, false);
    }
  };

  const fullName = `${player.firstName} ${player.familyName}`;

  return (
    <Box className={styles.container}>
      <FormControlLabel
        classes={{
          root: styles.labelRoot,
          label: styles.label,
        }}
        onClick={(event) => event.stopPropagation()}
        onFocus={(event) => event.stopPropagation()}
        control={
          <Checkbox
            color="primary"
            checked={selected === 'all'}
            indeterminate={selected === 'partial'}
            onChange={handleSelect}
          />
        }
        value={player.personId}
        label={
          <>
            <img src={player.images} alt={fullName} />
            <Typography variant="h4">{fullName}</Typography>
          </>
        }
      />
      <CheckboxFormGroup
        options={actions}
        value={actions.filter(({ value }) => player.shot.includes(value))}
        setValue={handleUpdate}
      />
    </Box>
  );
};

export default PlayerSelectItem;

import React, { useState, useEffect } from 'react';
import ShareSharpIcon from '@material-ui/icons/ShareSharp';
import { Button, Tooltip } from '@material-ui/core';

import { SHARE } from '../../constants/misc';

export interface ShareLinkButtonProps {
  url: string;
  size?: 'small' | 'medium';
  tooltipText?: string;
}

const ShareLinkButton: React.FC<ShareLinkButtonProps> = ({
  url,
  size = 'medium',
  tooltipText = 'Link copied!',
}) => {
  const [openTooltip, setOpenTooltip] = useState(false);

  const shareUrl = () => {
    const tempInput = document.createElement('input');
    tempInput.value = url;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    setOpenTooltip(true);
  };

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (openTooltip) {
      timer = setTimeout(() => {
        setOpenTooltip(false);
      }, 2000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [openTooltip]);

  return (
    <Tooltip title={tooltipText} open={openTooltip} arrow placement="top">
      <Button
        size={size}
        onClick={() => shareUrl()}
        startIcon={<ShareSharpIcon color="primary" />}
      >
        {SHARE}
      </Button>
    </Tooltip>
  );
};

export default ShareLinkButton;

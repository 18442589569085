import { TypographyOptions } from '@material-ui/core/styles/createTypography';

import breakpoints from './breakpoints';

const typography: TypographyOptions = {
  fontFamily: [
    'proxima-nova',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  fontSize: 13,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontWeight: 700,
    lineHeight: 1.15,
    fontSize: '1.8rem',
    [`@media (min-width: ${breakpoints.values.md}px)`]: {
      fontSize: '3rem',
    },
  },
  h2: {
    fontSize: '1.6rem',
    fontWeight: 700,
    lineHeight: 1.15,
    marginBottom: '0.2em',
  },
  h3: {
    fontSize: '1.2rem',
    fontWeight: 700,
    lineHeight: 1.15,
    marginBottom: '0.2em',
  },
  h4: {
    fontSize: '1.125rem',
    fontWeight: 700,
    lineHeight: 1.25,
  },
  h5: {
    fontSize: '1.0625rem',
    fontWeight: 500,
    lineHeight: 1.25,
  },
  h6: {
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: 1.15,
  },
  body1: {
    fontSize: '1rem',
  },
  body2: {
    fontSize: '1rem',
    lineHeight: 1.5,
  },
  button: {
    textTransform: 'none',
  },
  subtitle1: {
    fontSize: '0.8rem',
    lineHeight: 1.25,
    fontWeight: 700,
  },
  subtitle2: {
    fontSize: '0.9rem',
    lineHeight: 1.25,
    fontWeight: 400,
  },
};

export default typography;

import React from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { create } from 'jss';
import {
  jssPreset,
  CssBaseline,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core';

import { defaultTheme } from './theme/variants';
import Routes from './routes';

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point')!,
});

const App: React.FC = () => (
  <HelmetProvider>
    <Helmet defaultTitle="National Basketball League | NBL" />
    <StylesProvider jss={jss}>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <Routes />
      </ThemeProvider>
    </StylesProvider>
  </HelmetProvider>
);
export default App;

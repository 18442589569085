import React from 'react';
import {
  Box,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemAvatar,
  Grid,
  Button,
  ListItemSecondaryAction,
  List,
} from '@material-ui/core';
import { AddCircleOutlineSharp, RemoveSharp } from '@material-ui/icons';

import AppAvatar from '../AppAvatar';
import { ITeam } from '../../types';

export interface TeamSelectListProps {
  options?: ITeam[];
  setValue: (value: ITeam) => void;
  add?: boolean;
  wrap?: boolean;
  disabled?: boolean;
}

const TeamSelectList: React.FC<TeamSelectListProps> = ({
  options = [],
  setValue,
  disabled,
  add,
  wrap,
}) => (
  <List disablePadding>
    <Box overflow="hidden">
      <Grid container spacing={4}>
        {options.map((team, index) => (
          <Grid key={index} item xs={12} md={wrap ? 6 : 12} lg={wrap ? 4 : 12}>
            <ListItem
              key={index}
              button={add || undefined}
              onClick={() => add && setValue(team)}
              disableGutters
              disabled={disabled}
              style={{ padding: 0 }}
            >
              <ListItemAvatar>
                <AppAvatar src={team.image} />
              </ListItemAvatar>
              <ListItemText
                primary={team.name}
                primaryTypographyProps={{
                  component: 'h4',
                  variant: 'h6',
                  display: 'inline',
                }}
              />
              {add ? (
                <ListItemIcon style={{ minWidth: 40 }}>
                  <AddCircleOutlineSharp color="primary" />
                </ListItemIcon>
              ) : (
                <ListItemSecondaryAction>
                  <Button
                    onClick={() => setValue(team)}
                    color="primary"
                    endIcon={<RemoveSharp color="primary" />}
                  >
                    Remove
                  </Button>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          </Grid>
        ))}
      </Grid>
    </Box>
  </List>
);

export default TeamSelectList;

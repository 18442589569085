import { actions } from '../constants/actions';
import { CURRENT_SEASON_NUMBER } from '../constants/misc';
import {
  HighlightPayloadType,
  IClip,
  IPlayer,
  ITeam,
  PlayerPayloadType,
  QueueItemType,
} from '../types';

export const mapClipMetaData = (clip: IClip) => {
  const clipNew: IClip = { ...clip };

  if (clip.payload?.length) {
    clip.payload.forEach(
      (payload: {
        category: string;
        value: string;
        startTime?: number;
        endTime?: number;
        additionalProperties?: any;
      }) => {
        switch (payload.category) {
          // If START_OFFSET exists in payload, adjust the offset and duration using START_OFFSET value.
          case 'START_OFFSET':
            const startOffset = parseFloat(payload.value) / 1000;

            clipNew.offset = startOffset + (clipNew.offset || 0);
            clipNew.duration = clipNew.duration - startOffset;
            break;

          // If ACTION_FROM_PLAYER exists in payload, use startTime and endTime from payload.
          case 'ACTION_FROM_PLAYER':
            if (
              payload.additionalProperties &&
              Array.isArray(payload.additionalProperties.highlights)
            ) {
              const { startTime, endTime } = payload;

              clipNew.startTime = startTime;
              clipNew.endTime = endTime;
              clipNew.offset = startTime / 1000;
              clipNew.duration = (endTime - startTime) / 1000;
            }
            break;
        }
      }
    );
  }

  return clipNew;
};

export const mergeOverlappingClips = (clips: IClip[]) => {
  return clips.reduce((accumulator: IClip[], clip) => {
    if (!accumulator.length) {
      return [clip];
    }

    // Find an overlapping clip.
    const overlappingClip = accumulator.find(
      (item) =>
        item.assetId === clip.assetId &&
        item.startTime <= clip.endTime &&
        item.endTime >= clip.startTime
    );

    if (!overlappingClip) {
      return [...accumulator, clip];
    }

    // If found, merge current clip with overlapping clip.
    const { startTime, endTime } = overlappingClip;

    overlappingClip.startTime = Math.min(clip.startTime, startTime);
    overlappingClip.endTime = Math.max(clip.endTime, endTime);
    overlappingClip.offset = startTime / 1000;
    overlappingClip.duration = (endTime - startTime) / 1000;

    return accumulator.map((item) =>
      item.id === overlappingClip.id ? overlappingClip : item
    );
  }, []);
};

export const mapClips = (group: IClip[]) => {
  const clips = group.map((clip) => mapClipMetaData(clip));

  return mergeOverlappingClips(clips);
};

export const valueToPayload = (key: string, value: string) =>
  `(payload.category:${key} AND payload.value:(${value}))`;

export const currentSeasonQueryPayload = valueToPayload(
  'SEASON_NUMBER',
  `"${CURRENT_SEASON_NUMBER}"`
);

export const roundToString = (round: number) =>
  round === 22 ? 'SF' : round === 23 ? 'GF' : `${round}`;

export const createPlayerQuery = (player: IPlayer) => {
  const actionValues = player.shot?.length
    ? player.shot
    : actions.map(({ value }) => value);
  const value = actionValues
    .map((value) => `${player.personId}_${value}`)
    .join(' ');

  const queries: string[] = [];

  queries.push(valueToPayload('ACTION_FROM_PLAYER', value));
  queries.push(currentSeasonQueryPayload);

  const query = `${encodeURI(queries.join(' AND '))}`;

  return advancedSearchQuery(query);
};

export const advancedSearchQuery = (query: string) => {
  return `grouped?groupMode=Asset&page=0&pageSize=100&query=(${query})&sortDesc=true&sortMode=AssetStartDate`;
};

export const createHighlightQuery = (
  type: string,
  teams: ITeam[],
  round?: string
) => {
  const queries: string[] = [];

  queries.push(
    valueToPayload(
      'TEAM',
      `${teams.map(({ teamId }) => `${teamId}`).join(' ')}`
    )
  );
  queries.push(valueToPayload('ASSET_TYPE', `"${type}"`));

  queries.push(currentSeasonQueryPayload);

  if (round && round !== 'null') {
    queries.push(valueToPayload('ROUND_NUMBER', round));
  }

  return advancedSearchQuery(encodeURI(queries.join(' AND ')));
};

export const getSourceFromClips = (clips: IClip[]) => {
  const sortedClips = [...clips].sort((a, b) => {
    if (a.assetId !== b.assetId) {
      return 0;
    }

    return a.offset > b.offset ? 1 : -1;
  });

  return sortedClips.reduce(
    (acumulator, clip) =>
      `${acumulator}&id=${clip.assetId}&o=${clip.offset}&d=${clip.duration}`,
    `${process.env.REACT_APP_LINIUS_API_ENDPOINT}/v3/assembly/hls?`
  );
};

export const mapContentsToQueueItems = (
  content: {
    key: string;
    group: IClip[];
  }[],
  entity: 'video' | 'player' = 'video',
  id?: number,
  title?: string
) => {
  return content.map((asset) => {
    const clips = mapClips(asset.group);
    const duration = clips.reduce((total, clip) => total + clip.duration, 0);
    const highlight: QueueItemType = {
      id: id ? `${id}` : asset.key,
      created:
        entity === 'video' && clips.length
          ? clips[0].created
          : new Date().getTime(),
      title,
      entity,
      assetId: clips.length ? clips[0].assetId : '',
      source: getSourceFromClips(clips),
      clips,
      duration,
    };

    return highlight;
  });
};

export const flattenGroups = (
  content: {
    key: string;
    group: IClip[];
  }[]
) => {
  if (!content.length) {
    return [];
  }

  const firstRow = content[0];
  const group = content.reduce(
    (accumulator, asset) => [...accumulator, ...asset.group],
    []
  );

  firstRow.group = group;

  return [firstRow];
};

export const createHighlightTitle = (
  players: PlayerPayloadType[],
  max: number = 8
) => {
  const title = players
    .filter((_, index) => index < max)
    .reduce((str, player, index, arr) => {
      const name = `${players.length < 5 ? `${player.firstName} ` : ''}${
        player.familyName
      }`;
      const char = index === 0 ? '' : index < arr.length - 1 ? ', ' : ' & ';

      return `${str}${char}${name}`;
    }, '');

  return `${title} Highlights`;
};

export const sourceFromVideo = (
  video: HighlightPayloadType
): [string, number] => {
  if (video?.asset_ids && !Array.isArray(video.asset_ids)) {
    const assetIds = video.asset_ids.split(',');
    const offsets = video.offsets.split(',').map((value) => parseInt(value));
    const durations = video.durations
      .split(',')
      .map((value) => parseInt(value));
    const durationTotal = durations.reduce((total, value) => total + value, 0);
    const source = assetIds
      .reduce((str, id, index) => {
        if (offsets[index] && durations[index]) {
          return `${str}id=${id}&o=${offsets[index]}&d=${durations[index]}&`;
        }

        return str;
      }, `${process.env.REACT_APP_LINIUS_API_ENDPOINT}/v3/assembly/hls?`)
      .slice(0, -1);

    return [source, durationTotal];
  }

  return [undefined, undefined];
};

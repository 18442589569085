import {
  useState,
  useEffect,
  useRef,
  useCallback,
  RefObject,
  MutableRefObject,
} from 'react';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import { POSTER } from '../constants/images';
import { useVideoPlayerForcePlay } from './useVideoPlayerForcePlay';

type UseVideoPlayerType =
  | MutableRefObject<HTMLVideoElement>
  | RefObject<HTMLVideoElement>;

const useVideoPlayer = (
  src?: string,
  poster: string = POSTER,
  isPaused: boolean = true,
  isMuted: boolean = false,
  volume: number = 1,
  seekTo: number | null = null,
  onEnded?: Function
): UseVideoPlayerType => {
  const ref = useRef<HTMLVideoElement>();
  const [vjsPlayer, setPlayer] = useState<VideoJsPlayer>();
  const [isOnEndedSet, setOnEnded] = useState<boolean>(false);
  const player = useVideoPlayerForcePlay(vjsPlayer);
  const getPlayer = useCallback((): VideoJsPlayer => {
    const config: VideoJsPlayerOptions = {
      preload: 'auto',
      fluid: true,
      html5: {
        hlsjsConfig: {
          //enableWorker: true,
          //overrideNative: !videojs.browser.IS_SAFARI,
          //liveBackBufferLength: 500,
          //liveSyncDurationCount: 1,
        },
        vhs: {
          //overrideNative: !videojs.browser.IS_SAFARI,
          //limitRenditionByPlayerDimensions: true,
        },
        hls: {
          //overrideNative: !videojs.browser.IS_SAFARI,
        },
      },
      controlBar: {
        children: [
          'playToggle',
          'progressControl',
          'durationDisplay',
          'muteToggle',
          'fullscreenToggle',
        ],
      },
    };

    const player = videojs(ref.current, config);

    player.on('playing', () => {
      player.autoplay(true);
    });

    return player;
  }, []);

  useEffect(() => {
    const player = getPlayer();

    setPlayer(player);

    return () => {
      if (player) {
        player.dispose();
      }
    };
  }, [getPlayer]);

  useEffect(() => {
    if (player && poster) {
      player.poster(poster);
    }
  }, [player, poster]);

  useEffect(() => {
    if (player && src) {
      player.src({ type: 'application/x-mpegURL', src });
    }
  }, [player, src]);

  useEffect(() => {
    if (player && onEnded && !isOnEndedSet) {
      player.on('ended', () => {
        onEnded();
      });

      setOnEnded(true);
    }
  }, [player, onEnded, isOnEndedSet]);

  useEffect(() => {
    if (player) {
      // https://stackoverflow.com/questions/6877403/how-to-tell-if-a-video-element-is-currently-playing
      const isPlaying =
        player.currentTime() > 0 &&
        !player.paused() &&
        !player.ended() &&
        player.readyState() > 2;

      if (player.paused() && !isPaused) {
        // Pause other players.
        const otherPlayers = videojs.getPlayers();

        Object.keys(otherPlayers).forEach((key: string) => {
          const otherPlayer = otherPlayers[key];

          if (otherPlayer) {
            otherPlayer.pause();
          }
        });

        // Play this player.
        const playPromise = player.play();

        // Prevent DOMException errors.
        if (playPromise) {
          playPromise.then().catch(() => {});
        }
      } else if (isPlaying && isPaused) {
        player.pause();
      }
    }
  }, [player, isPaused]);

  useEffect(() => {
    if (player) {
      if (player.muted() !== isMuted) {
        player.muted(isMuted);
      }
    }
  }, [player, isMuted]);

  useEffect(() => {
    if (player) {
      player.volume(volume);
    }
  }, [player, volume]);

  useEffect(() => {
    if (player && seekTo !== null) {
      player.currentTime(seekTo);
    }
  }, [player, seekTo]);

  return ref;
};

export { useVideoPlayer };

import { OptionType } from '../types';

export const highlightTypes: OptionType[] = [
  { id: 'game_highlights', value: 'highlights', label: 'Game Highlights' },
  {
    id: 'condensed_game_replay',
    value: 'condensed game',
    label: 'Condensed Game Replays',
  },
  {
    id: 'press_conference',
    value: 'press conference',
    label: 'Press Conferences',
  },
  {
    id: 'player_highlights',
    value: 'player highlights',
    label: 'Player Highlights',
  },
  /*
  {
    id: 'full_game_replay',
    value: 'game replay',
    label: 'Full Game Replays',
  },
  */
];

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { selectUserId, setCredentials } from '../store/slices/user';

// Checks there is an user id either in search params or in state.
// Stores the id in state if found in search params.

const useSetCredentials = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userId = useSelector(selectUserId);
  const params = new URLSearchParams(location.search);
  const id = params.get('id');
  const email = params.get('email');
  const sig = params.get('sig');

  const userParams = id && email && sig;

  useEffect(() => {
    if (!userId && userParams) {
      dispatch(setCredentials({ id, email, sig }));
    }
  }, [dispatch, userId, userParams, id, email, sig]);

  return !!userId || !!userParams;
};

export default useSetCredentials;

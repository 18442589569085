import React from 'react';
import { CardMedia, makeStyles, Box, Theme } from '@material-ui/core';
import PlaySvgIcon from '../PlaySvgIcon';

const useCardMediaStyles = makeStyles<
  Theme,
  { percent: number; contain: boolean; active: boolean }
>(() => ({
  root: {
    opacity: ({ active }) => (active ? 0.75 : 1),
    position: 'relative',
    width: '100%',
    backgroundSize: ({ contain }) => (contain ? 'contain' : 'cover'),
    '&::before': {
      content: '""',
      display: 'block',
      width: '100%',
      paddingBottom: ({ percent }) => `${percent}%`,
    },
  },
}));

export interface CardImageProps {
  title?: string;
  image?: string;
  active?: boolean;
  hover?: boolean;
  percent?: number;
  contain?: boolean;
  light?: boolean;
}

const CardImage: React.FC<CardImageProps> = ({
  title,
  image = null,
  percent = 65,
  active,
  hover,
  contain,
  light,
}) => {
  const cardMediaStyles = useCardMediaStyles({ percent, contain, active });

  return (
    <Box bgcolor={light ? 'common.white' : '#060606'}>
      {image ? (
        <CardMedia
          image={image}
          src={image}
          title={title}
          classes={cardMediaStyles}
        >
          {!active && <PlaySvgIcon active={hover} />}
        </CardMedia>
      ) : (
        <Box className={cardMediaStyles.root} />
      )}
    </Box>
  );
};

export default CardImage;

import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { AccessTimeSharp } from '@material-ui/icons';

const useButtonStyles = makeStyles({
  root: {
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: 'initial',
      cursor: 'inherit',
    },
  },
});

export interface DurationProps {
  duration: number;
}

const Duration: React.FC<DurationProps> = ({ duration }) => {
  const buttonStyles = useButtonStyles();

  if (!duration) {
    return <></>;
  }

  const minutes = Math.floor(duration / 60);
  const seconds = Math.floor(duration - minutes * 60);
  const label = `${
    minutes > 0 ? `${minutes.toLocaleString()}m ` : ''
  }${seconds}secs`;

  return (
    <Button
      startIcon={<AccessTimeSharp color="primary" />}
      size="small"
      component="span"
      classes={buttonStyles}
    >
      {label}
    </Button>
  );
};

export default Duration;

import React from 'react';
import {
  makeStyles,
  Theme,
  Stepper,
  Step,
  StepLabel,
  Box,
  StepConnector,
  StepIconProps,
} from '@material-ui/core';

const useStepConnectorStyles = makeStyles((theme) => ({
  active: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  line: {
    borderColor: theme.palette.grey[600],
    borderTopWidth: 4,
    borderRadius: 0,
  },
}));

const useStepIconStyles = makeStyles<Theme, { active?: boolean }>((theme) => ({
  root: {
    width: 22,
    height: 22,
    borderWidth: 3,
    borderStyle: 'solid',
    borderRadius: '50%',
    paddingRight: 0,
    borderColor: ({ active }) =>
      active ? theme.palette.primary.main : theme.palette.grey[600],
  },
}));

const useStepLabelStyles = makeStyles({
  iconContainer: {
    padding: 0,
  },
});

const StepIconComponent: React.FC<StepIconProps> = ({ active, completed }) => {
  const stepIconStyles = useStepIconStyles({
    active: active || completed,
  });

  return <div className={stepIconStyles.root} />;
};

export interface ProgressStepperProps {
  total?: number;
  current?: number;
}

const ProgressStepper: React.FC<ProgressStepperProps> = ({
  total = 4,
  current = 0,
}) => {
  const stepConnectorStyles = useStepConnectorStyles();
  const stepLabelStyles = useStepLabelStyles();

  return (
    <Box marginY={8}>
      <Stepper
        style={{ padding: 0 }}
        activeStep={current}
        connector={<StepConnector classes={stepConnectorStyles} />}
      >
        {[...Array(total)].map((_, index) => (
          <Step key={index} style={{ padding: 0 }}>
            <StepLabel
              classes={stepLabelStyles}
              StepIconComponent={StepIconComponent}
            />
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default ProgressStepper;

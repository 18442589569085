import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  CardActionArea,
  makeStyles,
  Card,
  Link as MuiLink,
  CardActions,
  Typography,
  CardContent,
  CardProps,
  Box,
} from '@material-ui/core';
import { AddCircleOutlineSharp } from '@material-ui/icons';

import Duration from '../Duration';
import ShareLinkButton from '../ShareLinkButton';
import CardImage from '../CardImage';
import Day from '../Day';

const useCardActionAreaStyles = makeStyles({
  root: {
    position: 'relative',
    padding: 0,
    width: 304,
  },
});

const useLinkStyles = makeStyles({
  root: {
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
    },
  },
});

const useCardContentStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    paddingBottom: theme.spacing(4),
    minHeight: 120,
  },
}));

export interface VideoCardProps extends CardProps {
  title?: string;
  secondary?: string;
  href?: string;
  image?: string;
  date?: number;
  duration?: number;
  shareUrl?: string;
  light?: boolean;
  contain?: boolean;
  onClick?: () => void;
  addToQueue?: () => void;
}

const VideoCard: React.FC<VideoCardProps> = ({
  title,
  secondary,
  href,
  image,
  date,
  duration,
  shareUrl,
  light,
  contain,
  onClick,
  addToQueue,
  ...rest
}) => {
  const [hover, setHover] = useState(false);
  const linkStyles = useLinkStyles();
  const cardContentStyles = useCardContentStyles();
  const cardActionAreaStyles = useCardActionAreaStyles();

  return (
    <Card {...rest}>
      <CardActionArea
        component="div"
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        classes={cardActionAreaStyles}
      >
        <CardImage
          image={image}
          percent={56.25}
          hover={hover}
          light={light}
          contain={contain}
        />
        <CardContent classes={cardContentStyles}>
          <Box marginY={4}>
            <Day date={date} />
          </Box>
          <Typography variant="h3">
            <MuiLink
              to={href && href}
              color="inherit"
              underline="none"
              component={href ? Link : 'div'}
              classes={linkStyles}
              onClick={onClick && onClick}
            >
              {title}
            </MuiLink>
          </Typography>
          {secondary && (
            <Typography color="textSecondary" gutterBottom>
              {secondary}
            </Typography>
          )}
        </CardContent>
        {(duration || shareUrl || addToQueue) && (
          <CardActions style={{ padding: 0 }}>
            <Duration duration={duration} />
            {shareUrl && <ShareLinkButton url={shareUrl} />}
            {addToQueue && (
              <Button
                onClick={addToQueue}
                size="medium"
                color="primary"
                startIcon={<AddCircleOutlineSharp />}
              >
                <Typography color="secondary" variant="caption">
                  Add To Queue
                </Typography>
              </Button>
            )}
          </CardActions>
        )}
      </CardActionArea>
    </Card>
  );
};

export default VideoCard;

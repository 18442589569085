import React from 'react';
import { Divider } from '@material-ui/core';

import { POPULAR_CHANNELS } from '../../constants/misc';
import { useGetTopChannelsQuery } from '../../store/apis/liniusMiddleware';
import ChannelList from '../ChannelList';

export interface PopularChannelsProps {
  userId?: string;
}

const PopularChannels: React.FC<PopularChannelsProps> = ({ userId }) => {
  const { data = [], isLoading } = useGetTopChannelsQuery(userId, {
    skip: !userId,
  });

  return (
    <>
      <Divider />
      <ChannelList
        loading={isLoading}
        userId={userId}
        title={POPULAR_CHANNELS}
        channelIds={data.map((channel) => channel.user_id)}
      />
    </>
  );
};

export default PopularChannels;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { actions } from '../../constants/actions';
import {
  ITeam,
  QueueItemType,
  AdvancedSearchPayloadType,
  IClip,
  PlayerPayloadType,
  IAsset,
  OptionType,
} from '../../types';
import {
  createHighlightQuery,
  createPlayerQuery,
  mapContentsToQueueItems,
  flattenGroups,
  valueToPayload,
  advancedSearchQuery,
  mapClips,
  currentSeasonQueryPayload,
} from '../../utils';

// Hooks for Linuis API.
// https://redux-toolkit.js.org/rtk-query/overview

const liniusApi = createApi({
  reducerPath: 'liniusApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_LINIUS_API_ENDPOINT}/v3`,
  }),
  endpoints: (builder) => ({
    getAsset: builder.query<IAsset, string>({
      query: (id) => `asset/${id}`,
    }),
    getQueue: builder.query<
      QueueItemType[],
      {
        teams: ITeam[];
        highlights?: string[];
        round?: string;
      }
    >({
      async queryFn({ teams, highlights = [], round }, _api, _options, fetch) {
        if (teams.length) {
          const requests: Promise<QueueItemType[]>[] = [];

          // Get player highlights.
          if (!round && highlights.includes('player highlights')) {
            const players = teams.reduce(
              (players, team) => [...players, ...team.players],
              []
            );

            const playerRequests = players.map((player) => {
              const query = createPlayerQuery(player);

              return new Promise<QueueItemType[]>(async (resolve, reject) => {
                const response = (await fetch(`search/advanced/${query}`)) as {
                  data: AdvancedSearchPayloadType;
                };

                if (response.data?.content) {
                  const queueItems = mapContentsToQueueItems(
                    flattenGroups(response.data.content),
                    'player',
                    player.personId,
                    `${player.firstName} ${player.familyName} Highlights`
                  );

                  resolve(queueItems);
                }

                reject('No data.');
              });
            });

            requests.push(...playerRequests);
          }

          // Get highlights by category.
          const highlightRequests = highlights
            .filter((category) => category !== 'player highlights')
            .map((category) => {
              const query = createHighlightQuery(category, teams, round);

              return new Promise<QueueItemType[]>(async (resolve, reject) => {
                const response = (await fetch(`search/advanced/${query}`)) as {
                  data: AdvancedSearchPayloadType;
                };

                if (response.data?.content) {
                  const queueItems = mapContentsToQueueItems(
                    response.data.content,
                    'video'
                  );

                  resolve(queueItems);
                }

                reject('No data.');
              });
            });
          requests.push(...highlightRequests);

          const queueItems: QueueItemType[] = [];

          await Promise.allSettled(requests).then((responses) => {
            responses.forEach((response) => {
              if (response.status === 'fulfilled') {
                queueItems.push(...response.value);
              }
            });
          });

          const data = [...queueItems];

          return { data };
        }

        return { error: { status: 0, data: 'No teams provided.' } };
      },
    }),
    getClips: builder.query<
      IClip[],
      { players: PlayerPayloadType[]; actions: OptionType[]; match: number }
    >({
      async queryFn(values, _api, _options, fetch) {
        const actionValues = !values.actions.length
          ? actions.map(({ value }) => value)
          : values.actions.map(({ value }) => value);

        const players = values.players
          .map((player) =>
            actionValues
              .map((value) => `${player.personId.toString()}_${value} `)
              .join('')
          )
          .join('')
          .slice(0, -1);

        const queries = [
          encodeURI(valueToPayload('MATCH_ID', values.match.toString())),
          encodeURI(valueToPayload('ACTION_FROM_PLAYER', players)),
          encodeURI(currentSeasonQueryPayload),
        ];

        const request = `search/advanced/${advancedSearchQuery(
          queries.join(' AND ')
        )}`;

        const response = (await fetch(request)) as {
          data: AdvancedSearchPayloadType;
        };

        if (!response.data?.content?.length) {
          return {
            error: {
              status: 204,
              data: 'No video clips found. Please update your selection.',
            },
          };
        }

        const data: IClip[] = [];

        response.data.content.forEach((asset) => {
          if (asset.group?.length) {
            data.push(...mapClips(asset.group));
          }
        });

        return { data };
      },
    }),
  }),
});

export const { useGetQueueQuery, useGetClipsQuery, useGetAssetQuery } =
  liniusApi;

export default liniusApi;

import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

import spinner from './spinner.png';

const useLoaderStyles = makeStyles({
  size: {
    '&::before': {
      content: '""',
      display: 'block',
      width: '100%',
      height: 0,
      paddingBottom: '56.25%',
    },
  },
  offset: {
    transform: 'translate(-50%, -50%)',
  },
  image: {
    animation: '$logoRotate 3s infinite linear',
    display: 'block',
    width: '100%',
  },
  '@keyframes logoRotate': {
    '0%': {
      transform: 'rotate(0deg);',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
});

const ChannelPlayerLoader: React.FC = () => {
  const loaderStyles = useLoaderStyles();

  return (
    <Box
      bgcolor="#060606"
      width="100%"
      position="relative"
      overflow="hidden"
      className={loaderStyles.size}
    >
      <Box
        width="40%"
        position="absolute"
        top="50%"
        left="50%"
        className={loaderStyles.offset}
      >
        <img
          className={loaderStyles.image}
          data-testid="loading-image"
          src={spinner}
          alt="loading"
        />
      </Box>
    </Box>
  );
};

export default ChannelPlayerLoader;

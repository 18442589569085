import React from 'react';
import { Checkbox, FormGroup, FormControlLabel } from '@material-ui/core';

import { OptionType } from '../../types';

export interface CheckboxFormGroupProps {
  options?: OptionType[];
  value?: OptionType[];
  disabled?: boolean;
  setValue?: (value: OptionType[]) => void;
}

const CheckboxFormGroup: React.FC<CheckboxFormGroupProps> = ({
  options = [],
  value = [],
  disabled,
  setValue,
}) => (
  <FormGroup row>
    {options.map((option, index) => (
      <FormControlLabel
        label={option.label}
        key={index}
        checked={value.some(({ id }) => id === option.id)}
        disabled={disabled}
        onChange={(_, checked) =>
          setValue &&
          setValue(
            checked
              ? [...value, option]
              : value.filter(({ id }) => id !== option.id)
          )
        }
        control={<Checkbox color="primary" value={option.id} />}
      />
    ))}
  </FormGroup>
);

export default CheckboxFormGroup;

import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { KeyboardArrowRightSharp } from '@material-ui/icons';

import {
  YOUR_CHANNEL_NAME,
  EMAIL_HIGHLIGHTS,
  NEXT,
} from '../../constants/misc';
import { IChannel } from '../../types';
import AppListItem from '../AppListItem';

export interface FirstPageProps {
  setStep?: (value: number) => void;
}

const FirstPage: React.FC<FirstPageProps> = ({ setStep }) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    validateForm,
  } = useFormikContext<IChannel>();

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  return (
    <AppListItem title="Build your channel">
      <Box maxWidth={360}>
        <Box marginBottom={8}>
          <Typography>Name your channel.</Typography>
        </Box>
        <Box marginY={4}>
          <TextField
            fullWidth
            variant="filled"
            id="channel_name"
            label={YOUR_CHANNEL_NAME}
            defaultValue={values.channel_name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFieldTouched('channel_name', true);
              setFieldValue('channel_name', event.target.value);
            }}
          />
        </Box>
        {errors?.channel_name && touched?.channel_name && (
          <Alert severity="error">{errors.channel_name}</Alert>
        )}
        <Box marginY={4}>
          <TextField
            fullWidth
            variant="filled"
            id="full_name"
            label="Full name"
            defaultValue={values.full_name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('full_name', event.target.value);
            }}
          />
        </Box>
        <Box marginY={8}>
          <FormControlLabel
            checked={values.email_highlights}
            control={<Checkbox color="primary" />}
            label={
              <Typography variant="subtitle2">{EMAIL_HIGHLIGHTS}</Typography>
            }
            onChange={(_, checked) => {
              setFieldValue('email_highlights', checked);
            }}
          />
        </Box>
        <Box marginY={16}>
          <Button
            fullWidth
            disabled={
              !!errors?.full_name || !!errors?.email || !!errors?.channel_name
            }
            variant="contained"
            color="primary"
            size="large"
            endIcon={<KeyboardArrowRightSharp />}
            onClick={() => {
              setStep(1);
            }}
          >
            {NEXT}
          </Button>
        </Box>
      </Box>
    </AppListItem>
  );
};

export default FirstPage;

import React from 'react';
import { Avatar, AvatarProps, makeStyles, Theme } from '@material-ui/core';

const useAvatarStyles = makeStyles<Theme, { size: string }>((theme) => ({
  root: {
    height: ({ size }) => theme.spacing(size === 'large' ? 16 : 12),
    width: ({ size }) => theme.spacing(size === 'large' ? 16 : 12),
    backgroundColor: theme.palette.common.white,
    marginRight: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      height: ({ size }) => theme.spacing(size === 'large' ? 20 : 12),
      width: ({ size }) => theme.spacing(size === 'large' ? 20 : 12),
      marginRight: ({ size }) => theme.spacing(size === 'large' ? 8 : 4),
    },
  },
  fallback: {
    display: 'none',
  },
}));

export interface AppAvatarProps extends AvatarProps {
  size?: 'medium' | 'large';
}

const AppAvatar: React.FC<AppAvatarProps> = ({ size = 'medium', ...rest }) => {
  const avatarStyles = useAvatarStyles({ size });

  return <Avatar classes={avatarStyles} variant="square" {...rest} />;
};

export default AppAvatar;

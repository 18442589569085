import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

import user from './slices/user';
import liniusApi from './apis/liniusApi';
import genius from './apis/genius';
import liniusMiddleware from './apis/liniusMiddleware';

export const reducer = combineReducers({
  [user.name]: user.reducer,
  [liniusMiddleware.reducerPath]: liniusMiddleware.reducer,
  [liniusApi.reducerPath]: liniusApi.reducer,
  [genius.reducerPath]: genius.reducer,
});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(liniusMiddleware.middleware)
      .concat(liniusApi.middleware)
      .concat(genius.middleware),
});

setupListeners(store.dispatch);

export type IAppState = ReturnType<typeof reducer>;
export type AppDispatchType = typeof store.dispatch;

export default store;

import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  makeStyles,
  ListItemAvatar,
  Box,
} from '@material-ui/core';

import { HistoryType, IChannel } from '../../types';
import FollowButton from '../FollowButton';
import {
  useAddHistoryMutation,
  useGetChannelQuery,
  useGetFollowersQuery,
} from '../../store/apis/liniusMiddleware';
import ChannelAvatar from '../AppAvatar';

const useListItemStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(30),
  },
  gutters: {
    paddingLeft: theme.spacing(6),
  },
}));

interface ChannelListItemProps {
  channelId: string;
  userId: string;
  hide?: boolean;
  onLoaded?: (channel: IChannel) => void;
}

const ChannelListItem: React.FC<ChannelListItemProps> = ({
  channelId,
  userId,
  hide,
  onLoaded,
}) => {
  const history = useHistory();
  const listItemStyles = useListItemStyles();
  const {
    data: channel,
    isUninitialized,
    isLoading,
  } = useGetChannelQuery(channelId);
  const { data: followers = [] } = useGetFollowersQuery(channelId);
  const [addHistory] = useAddHistoryMutation();
  const followerCount = channel?.follower_count || 0;

  const onClick = useCallback(() => {
    history.push(`/channel/${channel.user_id}`);

    const body: HistoryType = {
      user_id: userId,
      channel_id: channel.user_id,
      channel_name: channel.channel_name,
      thumbnail_url:
        (channel && channel.teams.find((team) => !!team.image).image) ||
        `/images/logo.png`,
    };

    addHistory(body);
  }, [addHistory, channel, history, userId]);

  useEffect(() => {
    if (onLoaded && channel) {
      onLoaded(channel);
    }
  }, [onLoaded, channel]);

  const loading = isUninitialized || isLoading;

  if (hide) {
    return <></>;
  }

  return (
    <ListItem
      classes={listItemStyles}
      ContainerComponent="div"
      button
      disableGutters
      disabled={!channel}
      divider
      onClick={() => onClick()}
    >
      <ListItemAvatar>
        <ChannelAvatar
          src={
            (channel && channel.teams.find((team) => !!team.image).image) ||
            `/images/logo.png`
          }
          alt={channel?.channel_name}
        />
      </ListItemAvatar>
      {loading && (
        <Box width="100%">
          <Box bgcolor="grey.900" height={18} marginBottom={2} maxWidth={160} />
          <Box bgcolor="grey.900" height={14} marginBottom={2} maxWidth={100} />
        </Box>
      )}
      <ListItemText
        style={{ margin: 0 }}
        primary={!loading && channel?.channel_name}
        primaryTypographyProps={{
          component: 'h3',
          variant: 'h3',
          noWrap: true,
        }}
        secondary={
          !loading &&
          `${followerCount.toLocaleString()} Follower${
            followerCount !== 1 ? 's' : ''
          }`
        }
        secondaryTypographyProps={{
          variant: 'subtitle2',
          noWrap: true,
        }}
      />
      {!loading && userId !== channel?.user_id && (
        <ListItemSecondaryAction style={{ right: 0 }}>
          <FollowButton
            userId={userId}
            channelId={channel?.user_id}
            following={followers.some(
              (follower) => follower.follower_id === userId
            )}
          />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default ChannelListItem;

import React from 'react';
import { Box, SvgIcon, useTheme } from '@material-ui/core';

export interface PlaySvgIconProps {
  active?: boolean;
}

const PlaySvgIcon: React.FC<PlaySvgIconProps> = ({ active }) => {
  const { palette } = useTheme();

  return (
    <Box
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
      height={42}
      width={42}
      left="50%"
      top="50%"
      fontSize="0.9em"
      borderRadius="50%"
      boxShadow="0 4px 8px rgba(0, 0, 0, 0.25)"
      bgcolor={active ? palette.primary.main : 'white'}
      style={{
        transform: 'translate(-50%, -50%)',
      }}
    >
      <SvgIcon fontSize="inherit" viewBox="0 0 25 24">
        <path
          d="M1.35,24a1.39,1.39,0,0,1-.72-.2A1.34,1.34,0,0,1,0,22.67V1.33A1.34,1.34,0,0,1,.63.2,1.36,1.36,0,0,1,1.93.13l22.3,10.66a1.33,1.33,0,0,1,0,2.41L1.94,23.86A1.38,1.38,0,0,1,1.35,24ZM2.7,3.47V20.54L20.55,12Z"
          fill={active ? palette.secondary.main : palette.primary.main}
        />
      </SvgIcon>
    </Box>
  );
};

export default PlaySvgIcon;

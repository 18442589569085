import { createTheme } from '@material-ui/core';

import colors from './colors';
import typography from './typography';
import overrides from './overrides';
import breakpoints from './breakpoints';
import props from './props';
import shadows from './shadows';

const defaultProps = {
  spacing: 4,
  breakpoints,
  overrides,
  props,
  typography,
  shadows,
};

const defaultPalette = {
  primary: {
    main: colors.brand,
    contrastText: colors.white,
  },
  secondary: {
    main: colors.white,
    contrastText: colors.black,
  },
  tonalOffset: 0.1,
};

export const darkTheme = createTheme({
  ...defaultProps,
  palette: {
    ...defaultPalette,
    type: 'dark',
    background: {
      default: colors.black,
      paper: colors.black,
    },
    text: {
      primary: 'rgba(255, 255, 255, 0.95)',
      secondary: 'rgba(255, 255, 255, 0.5)',
    },
    divider: 'rgba(255, 255, 255, 0.2)',
  },
});

export const lightTheme = createTheme({
  ...defaultProps,
  palette: {
    ...defaultPalette,
    type: 'light',
    text: {
      primary: 'rgba(0, 0, 0, 0.95)',
      secondary: 'rgba(0, 0, 0, 0.5)',
    },
    divider: 'rgba(0, 0, 0, 0.2)',
  },
});

export const defaultTheme = darkTheme;

import React from 'react';
import {
  Grid,
  FormControl,
  FormGroup,
  CircularProgress,
} from '@material-ui/core';

import { IPlayer, OptionType } from '../../types';
import { useGetPlayersQuery } from '../../store/apis/genius';
import PlayerSelectItem from './PlayerSelectItem';
import { CURRENT_SEASON_GENIUS_COMP_ID } from '../../constants/misc';

export interface PlayerSelectListProps {
  actions: OptionType[];
  value: IPlayer[];
  teamId: number;
  setValue: (teamId: number, players: IPlayer[]) => void;
}

export type selectedStateType = 'all' | 'partial' | 'none';

const getSelected = (
  actions: OptionType[],
  option: IPlayer
): selectedStateType => {
  if (actions.every((action) => option.shot.includes(action.id))) {
    return 'all';
  } else if (option.shot.length !== 0) {
    return 'partial';
  } else {
    return 'none';
  }
};

const PlayerSelectList: React.FC<PlayerSelectListProps> = ({
  actions,
  value,
  teamId,
  setValue,
}) => {
  const {
    data = [],
    isUninitialized,
    isLoading,
  } = useGetPlayersQuery({
    competitionId: CURRENT_SEASON_GENIUS_COMP_ID,
    teamId,
  });
  const players = data.map((player) => {
    const existingPlayer = value.find((p) => p.personId === player.personId);

    if (existingPlayer) {
      return existingPlayer;
    }

    const newPlayer: IPlayer = {
      personId: player.personId,
      firstName: player.firstName,
      familyName: player.familyName,
      images: player.images?.photo?.T1?.url || '/images/player_default.jpg',
      teamId,
      shot: [],
    };

    return newPlayer;
  });

  const handleUpdate = (player: IPlayer, selected: boolean) => {
    const exists = value.some(({ personId }) => personId === player.personId);
    const newPlayers = selected
      ? exists
        ? value.map((p) => (p.personId === player.personId ? player : p))
        : [...value, player]
      : value.filter(({ personId }) => personId !== player.personId);

    setValue(teamId, newPlayers);
  };

  const playerItems = players.map((option) => {
    return (
      <PlayerSelectItem
        key={option.personId}
        player={option}
        actions={actions}
        selected={getSelected(actions, option)}
        onUpdate={handleUpdate}
      />
    );
  });

  if (isUninitialized || isLoading) {
    return <CircularProgress />;
  }

  return (
    <Grid container>
      <FormControl component="fieldset">
        <FormGroup>{playerItems}</FormGroup>
      </FormControl>
    </Grid>
  );
};

export default PlayerSelectList;

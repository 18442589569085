import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';

import { IAppState } from '..';

const user = createSlice({
  name: 'user',
  initialState: {
    id: '',
    email: '',
    sig: '',
  },
  reducers: {
    setCredentials: (
      state,
      {
        payload: { id, email, sig },
      }: PayloadAction<{ id: string; email: string; sig: string }>
    ) => {
      state.id = id;
      state.email = email;
      state.sig = sig;
    },
  },
});

export const { setCredentials } = user.actions;

export const selectUserSlice = (state: IAppState) => state.user;

export const selectUserId = createSelector(
  selectUserSlice,
  (state) => state.id
);

export default user;

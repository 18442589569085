import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';

const useDayStyles = makeStyles((theme) => ({
  root: {
    fontSize: 12,
    lineHeight: 1,
    paddingLeft: theme.spacing(3),
    borderLeft: `3px solid ${theme.palette.primary.main}`,
  },
}));

export interface DayProps {
  date: number;
  size?: 'medium' | 'large';
}

const Day: React.FC<DayProps> = ({ date, size = 'medium' }) => {
  const dayStyles = useDayStyles();

  const formatDate = (time: number) => {
    const date: Date = new Date(time * 1000);
    let today = new Date();
    let difference = today.getTime() - date.getTime();
    let days = difference / (1000 * 3600 * 24);
    let week = Math.floor(days / 7);
    let month = Math.round(week / 4);
    let year = Math.floor(days / 365);

    const displayTimePeriod = (
      timePeriod: number,
      timePeriodString: string
    ) => {
      return `${timePeriod} ${timePeriodString}${
        timePeriod === 1 ? '' : 'S'
      } AGO`;
    };
    if (days < 0) {
      return new Date(time).toString();
    } else if (days < 1) {
      return 'TODAY';
    } else if (days < 2) {
      return 'YESTERDAY';
    } else if (days >= 7 && week < 4) {
      return displayTimePeriod(week, 'WEEK');
    } else if (week >= 4 && days < 365) {
      return displayTimePeriod(month, 'MONTH');
    } else if (days >= 365) {
      return displayTimePeriod(year, 'YEAR');
    } else {
      return displayTimePeriod(Math.round(days), 'DAY');
    }
  };

  return (
    <Box paddingLeft={2} className={dayStyles.root}>
      <Typography
        variant="subtitle1"
        color="textSecondary"
        style={{
          fontSize: size === 'large' ? '1rem' : '0.8rem',
        }}
      >
        {formatDate(date)}
      </Typography>
    </Box>
  );
};

export default Day;

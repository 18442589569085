import React from 'react';
import { Box, Grid, Hidden, Typography } from '@material-ui/core';

import {
  MATCH_DATA_IS_UPDATED,
  NOW_SHOWING,
  NO_RESULTS_TO_DISPLAY,
} from '../../constants/misc';
import VideoPlayer from '../VideoPlayer';
import ChannelPlayerLoader from '../ChannelPlayerLoader';
import AppListItem from '../AppListItem';
import Time from '../Duration';

export interface ChannelPlayerProps {
  title: string;
  source: string;
  duration: number;
  loading: boolean;
  isEmpty?: boolean;
  onEnded?: Function;
}

const ChannelPlayer: React.FC<ChannelPlayerProps> = ({
  title,
  duration,
  source,
  loading,
  isEmpty,
  onEnded,
}) => {
  const renderDetails = () => (
    <Box marginY={4}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
      >
        <Grid item>
          <Typography variant="h3" component="h2" gutterBottom>
            {!loading && title}&nbsp;
          </Typography>
        </Grid>
        {!loading && (
          <Grid item>
            <Time duration={duration} />
          </Grid>
        )}
      </Grid>
    </Box>
  );

  return (
    <AppListItem title={NOW_SHOWING}>
      <>
        <Typography component="p" variant="subtitle2" color="textSecondary">
          {MATCH_DATA_IS_UPDATED}
        </Typography>
        <Box marginY={8}>
          {!loading && isEmpty ? (
            <Box marginY={8}>
              <Typography component="p" variant="body2">
                {NO_RESULTS_TO_DISPLAY}
              </Typography>
            </Box>
          ) : (
            <>
              <Hidden smDown>{renderDetails()}</Hidden>
              {loading ? (
                <ChannelPlayerLoader />
              ) : (
                <VideoPlayer
                  id="channel-video-player"
                  src={source}
                  isPaused={true}
                  onEnded={onEnded}
                />
              )}
              <Hidden mdUp>{renderDetails()}</Hidden>
            </>
          )}
        </Box>
      </>
    </AppListItem>
  );
};

export default ChannelPlayer;

import React from 'react';
import { Grid, Button, makeStyles, Theme, Box } from '@material-ui/core';

const useButtonStyles = makeStyles<Theme, { active?: boolean }>((theme) => ({
  root: {
    borderRadius: '50%',
    borderColor: ({ active }) =>
      active ? 'transparent' : 'rgba(255, 255, 255, 0.23)',
    color: ({ active }) => (active ? 'black' : theme.palette.text.secondary),
    backgroundColor: ({ active }) =>
      active ? theme.palette.primary.main : 'transparent',
    '&:hover': {
      color: ({ active }) => (active ? 'black' : theme.palette.text.primary),
      backgroundColor: ({ active }) =>
        active ? theme.palette.primary.main : 'rgba(255, 255, 255, 0.08)',
    },
  },
  sizeSmall: {
    width: 28,
    height: 28,
  },
}));

export interface RoundsProps {
  value: string | undefined;
  setRound: (value: string) => void;
  loading?: boolean;
}

// TODO round filters based on Genius response for current (+ previous?) season
// ... for now hard coding these as we only support the 2022-2023 season
// and the formatting may changes from either NBL or Genius side next season
const ROUNDS = [
  ...Array(18), // regular
  'Q',
  'PI',
  'SF',
  'GF',
];

const Rounds: React.FC<RoundsProps> = ({ value, setRound, loading }) => {
  const buttonStyles = useButtonStyles({});
  const buttonActiveStyles = useButtonStyles({ active: true });

  const roundInputs = ROUNDS.map((name, index) => {
    const round = name || String(index + 1);
    return (
      <Grid item key={index}>
        <Button
          size="small"
          variant="outlined"
          classes={round === value ? buttonActiveStyles : buttonStyles}
          onClick={() => setRound(round === value ? undefined : round)}
          disabled={loading}
        >
          {round}
        </Button>
      </Grid>
    );
  });

  return (
    <Box overflow="hidden">
      <Grid container spacing={2}>
        {roundInputs}
      </Grid>
    </Box>
  );
};

export default Rounds;

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { scroller } from 'react-scroll';
import {
  Grid,
  useMediaQuery,
  useTheme,
  Divider,
  Hidden,
} from '@material-ui/core';

import { highlightTypes } from '../../constants';
import { useGetQueueQuery } from '../../store/apis/liniusApi';
import { useGetChannelQuery } from '../../store/apis/liniusMiddleware';
import { useGetAssetQuery } from '../../store/apis/liniusApi';
import ChannelHeader from '../ChannelHeader';
import ChannelPlayer from '../ChannelPlayer';
import PopularChannels from '../PopularChannels';
import Followers from '../Followers';
import UpNext from '../UpNext';

interface MatchProps {
  id: string;
  entityId: string;
}

export interface ChannelProps {
  userId: string;
}

const Channel: React.FC<ChannelProps> = ({ userId }) => {
  const history = useHistory();
  const location = useLocation();
  const round = new URLSearchParams(location.search).get('round');
  const match = useRouteMatch<MatchProps>();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [prevChannelId, setPrevChannelId] = useState('');
  const { id, entityId } = match.params;
  const channelId = id || userId;
  const ref = useRef<HTMLDivElement>();

  // TODO: Move channel logic to into it's own hook.

  const { data: channelData } = useGetChannelQuery(channelId, {
    skip: !channelId,
  });
  const channel = channelData?.user_id === channelId ? channelData : undefined;

  const categoryOptions = channel
    ? highlightTypes.filter(({ id }) => channel.highlights.includes(id))
    : [];
  const [categories, setCategories] = useState(categoryOptions);

  const {
    data: highlights = [],
    isUninitialized,
    isLoading,
    isFetching,
  } = useGetQueueQuery(
    {
      teams: channel?.teams,
      highlights: categories.map(({ value }) => value),
      round,
    },
    { skip: !channel }
  );
  const loading = isUninitialized || isLoading || isFetching;

  const cuerrentItem = entityId
    ? highlights.find((item) => item.id === entityId)
    : highlights[0];

  const { data: asset, isFetching: isAssetFetching } = useGetAssetQuery(
    cuerrentItem?.assetId,
    { skip: !cuerrentItem?.assetId }
  );
  const currentItemName =
    cuerrentItem?.title || (isAssetFetching ? '' : asset?.name);
  const title = `National Basketball League | NBL | ${currentItemName}`;

  const setRound = (round?: string) => {
    history.push(`/channel/${channelId}${round ? `?round=${round}` : ''}`);
  };

  const handleOnEnd = useCallback(() => {
    const index = highlights.findIndex(({ id }) => id === cuerrentItem?.id) + 1;

    if (index && index < highlights.length) {
      const newItem = highlights[index];

      history.push(`/channel/${channelId}/${newItem.entity}/${newItem.id}`);
    }
  }, [history, cuerrentItem, highlights, channelId]);

  const END_EVENT = 'NBL_VIDEO_PLAYER_ENDED';

  const onEnded = () => {
    ref.current.dispatchEvent(new Event(END_EVENT));
  };

  useEffect(() => {
    const refElement = ref.current;
    refElement.addEventListener(END_EVENT, handleOnEnd);

    return () => {
      refElement.removeEventListener(END_EVENT, handleOnEnd);
    };
  }, [handleOnEnd]);

  useEffect(() => {
    if (channel) {
      const categories = highlightTypes.filter(({ id }) =>
        channel.highlights.includes(id)
      );

      setCategories(categories);
    }
  }, [channel]);

  useEffect(() => {
    setPrevChannelId(channelId);

    if (prevChannelId && channelId !== prevChannelId) {
      scroller.scrollTo('scroll-channel', {
        duration: 0,
      });
    }
  }, [channelId, prevChannelId]);

  return (
    <>
      <Helmet title={title} />
      <ChannelHeader userId={userId} channelId={channelId} />
      <Divider />
      <Grid container spacing={isDesktop ? 8 : 0}>
        <Grid item xs={12} md={8}>
          <div ref={ref}>
            <ChannelPlayer
              isEmpty={highlights.length === 0}
              title={currentItemName}
              source={cuerrentItem?.source}
              duration={cuerrentItem?.duration}
              loading={loading}
              onEnded={onEnded}
            />
          </div>
          <Hidden mdUp>
            <UpNext
              currentId={cuerrentItem?.id}
              loading={loading}
              channelId={channelId}
              items={highlights}
              round={round}
              setRound={setRound}
              categoryOptions={categoryOptions}
              categoryValue={categories}
              setCategories={setCategories}
            />
          </Hidden>
          <Grid container spacing={isDesktop ? 8 : 0}>
            <Grid item xs={12} md={6}>
              <Followers
                userId={userId}
                channelId={channelId}
                total={channel?.follower_count}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PopularChannels userId={userId} />
            </Grid>
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid item xs={12} md={4}>
            <UpNext
              currentId={cuerrentItem?.id}
              loading={loading}
              channelId={channelId}
              items={highlights}
              round={round}
              setRound={setRound}
              categoryOptions={categoryOptions}
              categoryValue={categories}
              setCategories={setCategories}
            />
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
};

export default Channel;

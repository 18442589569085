import React from 'react';
import { Container, makeStyles } from '@material-ui/core';

const useContainerStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },
}));

const ContainerLayout: React.FC = ({ children }) => {
  const containerStyles = useContainerStyles();

  return (
    <Container maxWidth="xl" classes={containerStyles} className="page-top">
      {children}
    </Container>
  );
};

export default ContainerLayout;

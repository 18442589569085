import React from 'react';
import { Box, CircularProgress, Divider, Hidden } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { QueueItemType } from '../../types';
import QueueCard from '../QueueCard';

export interface QueueProps {
  channelId: string;
  items: QueueItemType[];
  currentId?: string;
  loading?: boolean;
  round?: string;
}

const Queue: React.FC<QueueProps> = ({
  loading,
  channelId,
  currentId,
  items,
  round,
}) => {
  if (loading) {
    return (
      <Box
        display="flex"
        width="100%"
        height={160}
        alignItems="center"
        justifyContent="center"
        overflow="hidden"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (items.length) {
    return (
      <Box width="100%" overflow="auto">
        <Box
          display="flex"
          flexWrap="nowrap"
          flexDirection={['row', 'row', 'column']}
        >
          {items.map((queueItem, index) => (
            <Box key={index} width="100%">
              <QueueCard
                id={queueItem.id}
                title={queueItem.title}
                secondary={queueItem.entity === 'player' ? ' ' : undefined}
                assetId={queueItem.assetId}
                duration={queueItem.duration}
                url={`/channel/${channelId}/${queueItem.entity}/${
                  queueItem.id
                }${round ? '' : `?round=${round}`}`}
                active={currentId === queueItem.id}
              />
              <Hidden smDown>
                <Divider />
              </Hidden>
            </Box>
          ))}
        </Box>
      </Box>
    );
  }

  return (
    <Box paddingY={4}>
      <Alert severity="warning">
        There doesn't appear to be any content for your selection.
      </Alert>
    </Box>
  );
};

export default Queue;

import React from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Hidden,
  Grid,
  useMediaQuery,
  useTheme,
  Typography,
  makeStyles,
  Button,
  IconButton,
} from '@material-ui/core';
import { AccountCircleSharp, SettingsSharp } from '@material-ui/icons';

import {
  useGetChannelQuery,
  useGetFollowersQuery,
} from '../../store/apis/liniusMiddleware';
import FollowButton from '../FollowButton';
import ChannelAvatar from '../AppAvatar';

const useIconButtonStyles = makeStyles({
  root: {
    '& .MuiSvgIcon-root': {
      fontSize: '2rem',
    },
  },
});

export interface ChannelHeaderProps {
  userId: string;
  channelId: string;
}

const ChannelHeader: React.FC<ChannelHeaderProps> = ({ userId, channelId }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const iconButtonStyles = useIconButtonStyles();
  const { data: channel } = useGetChannelQuery(channelId, {
    skip: !channelId,
  });
  const { data: followers = [] } = useGetFollowersQuery(channelId, {
    skip: !channelId,
  });
  // DL-1474
  // const shareLink = `${process.env.REACT_APP_NBL_HOSTED_APP_ENDPOINT}/channel/${channelId}`;
  const channelLink = userId === channelId ? '/build-your-channel' : '/channel';
  const channelIcon =
    userId === channelId ? (
      <SettingsSharp color="primary" />
    ) : (
      <AccountCircleSharp color="primary" />
    );

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      spacing={isDesktop ? 8 : 4}
      wrap="nowrap"
      className="scroll-channel"
    >
      <Grid item md={8}>
        <Box paddingY={[4, 4, 8]}>
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item>
              <ChannelAvatar
                size="large"
                alt={channel && channel.channel_name}
                src={
                  (channel &&
                    channel.teams.find((team) => !!team.image).image) ||
                  `/images/logo.png`
                }
              />
            </Grid>
            <Grid item>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Box width="100%" marginRight={4}>
                    <Typography variant="h1" noWrap>
                      {channel && channel.channel_name}
                    </Typography>
                  </Box>
                </Grid>
                {channel && (
                  <Grid item>
                    <Grid
                      container
                      spacing={4}
                      alignItems="center"
                      wrap="nowrap"
                    >
                      {userId !== channelId && (
                        <Grid item>
                          <FollowButton
                            variant="outlined"
                            color="primary"
                            userId={userId}
                            channelId={channelId}
                            following={followers.some(
                              (follower) => follower.follower_id === userId
                            )}
                          />
                        </Grid>
                      )}
                      {/* DL-1474 */}
                      {/* <Grid item>
                        <ShareLinkButton url={shareLink} />
                      </Grid> */}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item md={3}>
        <Hidden smDown>
          <Button
            size="large"
            variant="outlined"
            color="primary"
            fullWidth
            endIcon={channelIcon}
            component={Link}
            to={channelLink}
          >
            {userId === channelId ? 'Channel Settings' : 'My Channel'}
          </Button>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={12} xl={6}>
            <IconButton
              size="medium"
              color="primary"
              component={Link}
              to={channelLink}
              classes={iconButtonStyles}
            >
              {channelIcon}
            </IconButton>
          </Grid>
        </Hidden>
      </Grid>
    </Grid>
  );
};

export default ChannelHeader;

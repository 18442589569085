import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardProps,
  CardActions,
  CardContent,
  Grid,
  Typography,
  CardActionArea,
  makeStyles,
  Box,
  Link as MuiLink,
  Theme,
} from '@material-ui/core';
import dateFormat from 'dateformat';

import { useGetAssetQuery } from '../../store/apis/liniusApi';
import Time from '../Duration';
import CardImage from '../CardImage';

const useCardActionAreaStyles = makeStyles<Theme, { active?: boolean }>(
  (theme) => ({
    root: {
      backgroundColor: ({ active }) => (active ? '#060606' : 'initial'),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        width: 180,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
  })
);

const useCardRightStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      minHeight: 200,
    },
  },
}));

const useLinkStyles = makeStyles({
  root: {
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
    },
  },
});

export interface QueueCardProps extends CardProps {
  id: string;
  title?: string;
  image?: string;
  url?: string;
  duration?: number;
  secondary?: string;
  assetId?: string;
  active?: boolean;
  light?: boolean;
  contain?: boolean;
}

const QueueCard: React.FC<QueueCardProps> = ({
  id,
  title,
  image,
  url,
  secondary,
  assetId,
  duration,
  active,
  light,
  contain,
  ...rest
}) => {
  const [hover, setHover] = useState(false);
  const cardActionAreaStyles = useCardActionAreaStyles({ active });
  const cardRightStyles = useCardRightStyles();
  const linkStyles = useLinkStyles();
  const { data: asset, isLoading } = useGetAssetQuery(assetId, {
    skip: !assetId,
  });
  const secondaryText =
    secondary ||
    (asset ? dateFormat(new Date(asset.startDate), 'mmmm dS, yyyy') : '');

  return (
    <Card {...rest}>
      <CardActionArea
        classes={cardActionAreaStyles}
        component="div"
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        <Grid container alignItems="stretch" spacing={4}>
          <Grid item xs={12} md={5}>
            <Box position="relative">
              <CardImage
                image={image || asset?.thumbnailUrl}
                title={title}
                active={active}
                hover={hover}
                light={light}
                contain={contain}
              />
              {active && (
                <Box
                  bgcolor="primary.main"
                  position="absolute"
                  bottom={0}
                  width="100%"
                >
                  <Typography align="center">NOW SHOWING</Typography>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={7} classes={cardRightStyles}>
            <CardContent>
              <Box paddingRight={[0, 0, 2]}>
                {isLoading ? (
                  <>
                    <Box
                      bgcolor="grey.900"
                      height={18}
                      marginBottom={2}
                      maxWidth={180}
                    />
                    <Box
                      bgcolor="grey.900"
                      height={18}
                      marginBottom={2}
                      maxWidth={120}
                    />
                  </>
                ) : (
                  <>
                    <MuiLink
                      to={url}
                      underline="none"
                      component={Link}
                      classes={linkStyles}
                    >
                      <Typography
                        variant="h3"
                        component="h3"
                        color={active ? 'textSecondary' : 'textPrimary'}
                        gutterBottom
                      >
                        {title || asset?.name}
                      </Typography>
                    </MuiLink>
                    {secondaryText && (
                      <Typography
                        component="p"
                        variant="subtitle2"
                        color="primary"
                        gutterBottom
                      >
                        {secondaryText}
                      </Typography>
                    )}
                  </>
                )}
              </Box>
            </CardContent>
            {duration && (
              <CardActions>
                {isLoading ? (
                  <Box bgcolor="grey.900" height={14} width={80} />
                ) : (
                  <Time duration={duration} />
                )}
              </CardActions>
            )}
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
};

export default QueueCard;

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Typography, Box } from '@material-ui/core';

const Page404: React.FC = () => (
  <>
    <Helmet title="National Basketball League | NBL | Page not found" />
    <Box paddingY={16}>
      <Typography variant="h1" align="center">
        404
      </Typography>
      <Typography variant="body2" align="center">
        Page not found.
      </Typography>
    </Box>
  </>
);

export default Page404;

import React, { useState, useCallback } from 'react';
import {
  Box,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { SearchSharp } from '@material-ui/icons';

import { IChannel } from '../../types';
import AppListItem from '../AppListItem';
import ChannelListItem from './ChannelListItem';

export interface ChannelListProps {
  title: string;
  secondary?: string;
  userId?: string;
  channelIds?: string[];
  limit?: number;
  maxHeight?: number;
  showSearch?: boolean;
  loading?: boolean;
}

const ChannelList: React.FC<ChannelListProps> = ({
  title,
  secondary = '',
  userId,
  channelIds = [],
  limit,
  maxHeight = 3 * 81,
  showSearch,
  loading,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [search, setSearch] = useState('');
  const [channels, setChannels] = useState<IChannel[]>([]);

  const searchIndex = useCallback(
    (id: string) => {
      const channel = channels.find(({ user_id }) => user_id === id);

      if (channel) {
        return channel.channel_name
          .toLocaleLowerCase()
          .indexOf(search.toLocaleLowerCase());
      }

      return -1;
    },
    [channels, search]
  );

  const channelIdsSorted = search.length
    ? [...channelIds].sort((a, b) => searchIndex(a) - searchIndex(b))
    : channelIds;

  const addChannelToSearch = (channel: IChannel) => {
    if (!channels.some(({ user_id }) => user_id === channel.user_id)) {
      setChannels((value) => [...value, channel]);
    }
  };

  return (
    <AppListItem
      title={title}
      secondary={secondary}
      maxHeight={maxHeight}
      endAdornment={
        isDesktop &&
        showSearch &&
        channelIds.length > 1 && (
          <Box maxWidth={140}>
            <TextField
              value={search}
              placeholder="Search"
              onChange={(event) => {
                setSearch(event.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchSharp />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        )
      }
    >
      {!loading &&
        channelIdsSorted.map((id, index) => (
          <ChannelListItem
            key={id}
            channelId={id}
            userId={userId}
            hide={
              search.length && (searchIndex(id) < 0 || (limit && index < limit))
            }
            onLoaded={addChannelToSearch}
          />
        ))}
    </AppListItem>
  );
};

export default ChannelList;

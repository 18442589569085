import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { Formik } from 'formik';
import * as Yup from 'yup';

import {
  useGetChannelQuery,
  useUpdateChannelMutation,
} from '../store/apis/liniusMiddleware';
import { selectUserSlice } from '../store/slices/user';
import { IChannel } from '../types';
import FormLayout from '../layouts/FormLayout';
import { Step1, Step2, Step3 } from '../components/ChannelForms';

const BuildChannelPage: React.FC = () => {
  const history = useHistory();
  const { id: userId, email } = useSelector(selectUserSlice);
  const { data: channel } = useGetChannelQuery(userId, { skip: !userId });
  const [updateChannel] = useUpdateChannelMutation();
  const [step, setStep] = useState(0);

  const buildChannelSchema = Yup.object().shape({
    channel_name: Yup.string()
      .min(2, 'Channel name must have at least two characters.')
      .max(30, 'Channel name must be thirty characters or less.')
      .required('Channel name is required.'),
    teams: Yup.array().min(1, 'At least one team must be selected.'),
    highlights: Yup.array().min(1, 'At least one category must be selected.'),
  });

  return (
    <>
      <Helmet
        title={`National Basketball League | NBL | Build your channel | Step ${
          step + 1
        }`}
      />
      <FormLayout index={step}>
        <Formik
          initialValues={{
            full_name: channel?.full_name || '',
            channel_name: channel?.channel_name || '',
            email_highlights: channel?.email_highlights || false,
            teams: channel?.teams || [],
            highlights: channel?.highlights || [],
          }}
          validationSchema={buildChannelSchema}
          onSubmit={async (values) => {
            const newChannel: Partial<IChannel> = {
              user_id: userId,
              email,
              full_name: values.full_name,
              channel_name: values.channel_name,
              email_highlights: values.email_highlights,
              teams: values.teams,
              highlights: values.highlights,
            };

            await updateChannel(newChannel);

            history.push('/channel');
          }}
        >
          {step === 2 ? (
            <Step3 setStep={setStep} />
          ) : step === 1 ? (
            <Step2 setStep={setStep} />
          ) : (
            <Step1 setStep={setStep} />
          )}
        </Formik>
      </FormLayout>
    </>
  );
};

export default BuildChannelPage;

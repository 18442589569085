import React from 'react';
import { POSTER } from '../../constants/images';
import { useVideoPlayer } from '../../hooks/useVideoPlayer';

import './VideoPlayer.styles.css';

export interface VideoPlayerProps {
  src: string;
  poster?: string;
  id?: string;
  display?: string;
  isMuted?: boolean;
  isPaused?: boolean;
  onEnded?: Function;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  src,
  poster = POSTER,
  id = 'video-player',
  isMuted = false,
  isPaused = false,
  display = 'block',
  onEnded,
}) => {
  const ref = useVideoPlayer(src, poster, isPaused, isMuted, 1, null, onEnded);

  return (
    <div style={{ display, width: '100%' }}>
      <video
        width="100%"
        data-vjs-player
        className="video-js vjs-default-skin vjs-fluid"
        id={id}
        ref={ref}
        controls
        preload="auto"
      />
    </div>
  );
};

export default VideoPlayer;

import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { IconButton, Box, Grid, Divider, Typography } from '@material-ui/core';
import { ClearSharp } from '@material-ui/icons';

import { sourceFromVideo } from '../../utils';
import { useGetShareVideoQuery } from '../../store/apis/liniusMiddleware';
import Layout from '../../layouts/ContainerLayout';
import VideoPlayer from '../VideoPlayer';
import Time from '../Duration';
import Day from '../Day';

export interface ShareMatchParams {
  id: string;
  timestamp: string;
}

const Share: React.FC = () => {
  const [open, setOpen] = useState(true);
  const [props, setProps] = useState<ShareMatchParams>();
  const { params } = useRouteMatch<ShareMatchParams>();
  const { data } = useGetShareVideoQuery(
    { id: props?.id, timestamp: props?.timestamp },
    { skip: !props?.timestamp || !props?.id }
  );

  useEffect(() => {
    if (params?.id && params?.timestamp) {
      setProps({
        id: params.id,
        timestamp: params.timestamp,
      });
    }
  }, [params]);

  if (data?.asset_ids && open) {
    const [source, duration] = sourceFromVideo(data);

    if (source) {
      return (
        <Layout>
          <Box marginY={4} textAlign="right">
            <IconButton onClick={() => setOpen(false)}>
              <ClearSharp />
            </IconButton>
          </Box>
          <Box marginBottom={8}>
            <Box overflow="hidden" marginY={2}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Box marginBottom={4}>
                    <Day date={data.timestamp} size="large" />
                  </Box>
                  <Box marginY={4}>
                    <Typography variant="h1">{data?.title}</Typography>
                  </Box>
                  <Time duration={duration} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <VideoPlayer id="share-video-player" src={source} />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Divider />
        </Layout>
      );
    }
  }

  return <></>;
};

export default Share;

import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';

import ChannelPage from '../pages/ChannelPage';
import BuildChannelPage from '../pages/BuildChannelPage';
import PrivateRoute from '../components/PrivateRoute';
import VideoShare from '../components/Share';

const ChannelRoutes: React.FC = () => (
  <>
    <VideoShare />
    <Switch>
      <Redirect exact from="/channelshare/:id" to="/channel/:id" />
      <Route exact path="/build-your-channel">
        <BuildChannelPage />
      </Route>
      <PrivateRoute exact path={['/', '/highlights/:id?/:timestamp?']}>
        <Redirect to="/channel" />
      </PrivateRoute>
      <PrivateRoute path="/channel/:id?/(video|player)?/:entityId?">
        <ChannelPage />
      </PrivateRoute>
    </Switch>
  </>
);

export default ChannelRoutes;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';

import {
  IChannel,
  IChannelFollower,
  ITopChannel,
  HistoryType,
  HighlightPayloadType,
  UserState,
} from '../../types';

// Hooks for Linius Middleware API.
// https://redux-toolkit.js.org/rtk-query/overview

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_LINIUS_MIDDLEWARE_ENDPOINT,
});

const signedBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = (args, api, extraOptions) => {
  const { user } = api.getState() as { user: UserState };
  const params = new URLSearchParams({
    i: user.id,
    e: user.email,
    s: user.sig,
  }).toString();

  const url = typeof args === 'string' ? args : args.url;
  const signedUrl = url.includes('?') ? `${url}&${params}` : `${url}?${params}`;

  const fetchArgs =
    typeof args === 'string' ? signedUrl : { ...args, url: signedUrl };

  return baseQuery(fetchArgs, api, extraOptions);
};

export const liniusMiddleware = createApi({
  reducerPath: 'liniusMiddleware',
  tagTypes: ['Channels', 'Followers', 'History', 'Highlights'],
  baseQuery: signedBaseQuery,
  endpoints: (builder) => ({
    getChannel: builder.query<IChannel, string>({
      query: (id) => `prefs/${id}`,
      providesTags: (_result, _response, id) => [{ type: 'Channels', id }],
    }),
    updateChannel: builder.mutation<void, Partial<IChannel>>({
      query: (body) => ({
        url: 'prefs',
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(body),
      }),
      invalidatesTags: (_result, _response, body) => [
        { type: 'Channels', id: body.user_id },
      ],
    }),
    getFollowers: builder.query<IChannelFollower[], string>({
      query: (id) => `follow/${id}`,
      transformResponse: (response: IChannelFollower[] | { message: string }) =>
        Array.isArray(response) ? response : [],
      providesTags: (_result, _response, id) => [{ type: 'Followers', id }],
    }),
    addHistory: builder.mutation<void, HistoryType>({
      query: (body) => ({
        url: 'visit-channels',
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(body),
      }),
      invalidatesTags: (_result, _response, body) => [
        { type: 'History', id: body.user_id },
      ],
    }),
    getHistory: builder.query<HistoryType[], string>({
      query: (id) => `visit-channels/${id}`,
      transformResponse: (response: { Items: HistoryType[] }) =>
        response.Items ? response.Items : [],
      providesTags: (_result, _response, id) => [{ type: 'History', id }],
    }),
    updateFollowers: builder.mutation<
      string,
      {
        user_id: string;
        follow: boolean;
        follower_id: string;
      }
    >({
      query: (body) => ({
        url: 'follow',
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(body),
      }),
      invalidatesTags: (_result, _response, body) => [
        { type: 'Channels', id: body.user_id },
        { type: 'Followers', id: body.user_id },
      ],
    }),
    getTopChannels: builder.query<ITopChannel[], string>({
      query: (id) => `get-top-channels/v2?userId=${id}`,
      transformResponse: (response: { top_channels: ITopChannel[] }) =>
        response.top_channels || [],
    }),
    getHighlights: builder.query<HighlightPayloadType[], string>({
      query: (id) => `highlights/${id}`,
      transformResponse: (response: { Items: HighlightPayloadType[] }) =>
        response.Items || [],
      providesTags: ['Highlights'],
    }),
    createHighlight: builder.mutation<string, Partial<HighlightPayloadType>>({
      query: (body) => ({
        url: 'highlights',
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(body),
      }),
      invalidatesTags: ['Highlights'],
    }),
    getShareVideo: builder.query<
      HighlightPayloadType,
      { id: string; timestamp: string }
    >({
      query: ({ id, timestamp }) => `highlights/${id}/${timestamp}`,
    }),
  }),
});

export const {
  useGetChannelQuery,
  useGetTopChannelsQuery,
  useUpdateChannelMutation,
  useGetFollowersQuery,
  useUpdateFollowersMutation,
  useGetHistoryQuery,
  useAddHistoryMutation,
  useCreateHighlightMutation,
  useGetHighlightsQuery,
  useGetShareVideoQuery,
} = liniusMiddleware;

export default liniusMiddleware;

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Page404 from '../pages/Page404';
import NoIdPage from '../pages/NoIdPage';
import ChannelRoutes from './ChannelRoutes';
import useSetCredentials from '../hooks/useSetCredentials';

const AppRoutes: React.FC = () => {
  const hasUserId = useSetCredentials();

  return (
    <Switch>
      <Route
        exact
        path={[
          '/',
          '/build-your-channel',
          '/channelshare/:id',
          '/channel/:id?/(video|player)?/:entityId?',
          '/highlights/:id?/:timestamp?',
        ]}
      >
        {hasUserId ? <ChannelRoutes /> : <Redirect to="/noid" />}
      </Route>
      <Route exact path="/noid">
        <NoIdPage />
      </Route>
      <Route>
        <Page404 />
      </Route>
    </Switch>
  );
};

export default AppRoutes;

import React from 'react';
import { Divider } from '@material-ui/core';

import { FOLLOWERS, MY_FOLLOWERS } from '../../constants/misc';
import { useGetFollowersQuery } from '../../store/apis/liniusMiddleware';
import ChannelList from '../ChannelList';

export interface FollowersProps {
  userId: string;
  channelId: string;
  total?: number;
}

const Followers: React.FC<FollowersProps> = ({
  userId,
  channelId,
  total = 0,
}) => {
  const { data = [], isLoading } = useGetFollowersQuery(channelId, {
    skip: !channelId,
  });

  return (
    <>
      <Divider />
      <ChannelList
        userId={userId}
        title={userId === channelId ? MY_FOLLOWERS : FOLLOWERS}
        secondary={`${total.toLocaleString()} Follower${
          total !== 1 ? 's' : ''
        }`}
        channelIds={data.map((follower) => follower.follower_id)}
        showSearch
        loading={isLoading}
      />
    </>
  );
};

export default Followers;

import { OptionType } from '../types';

export const actions: OptionType[] = [
  { id: 'dunk', value: 'dunk', label: 'Dunks' },
  { id: 'block', value: 'block', label: 'Blocks' },
  { id: 'assist', value: 'assist', label: 'Assists' },
  { id: 'steal', value: 'steal', label: 'Steals' },
  { id: 'rebound', value: 'rebound', label: 'Rebounds' },
  { id: '3pt', value: '3pt', label: '3pt makes' },
  { id: '2pt', value: '2pt', label: '2pt makes' },
  { id: 'freethrow', value: 'freethrow', label: 'Free throws' },
  { id: 'foul', value: 'foul', label: 'Fouls' },
  { id: 'foulon', value: 'foulon', label: 'Foul on' },
  { id: '2ptmiss', value: '2ptmiss', label: '2pt misses' },
  { id: '3ptmiss', value: '3ptmiss', label: '3pt misses' },
  { id: 'dunkmiss', value: 'dunkmiss', label: 'Dunk misses' },
];

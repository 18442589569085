import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import {
  Button,
  Typography,
  Grid,
  Box,
  CircularProgress,
} from '@material-ui/core';
import {
  KeyboardArrowLeftSharp,
  KeyboardArrowRightSharp,
} from '@material-ui/icons';

import {
  BACK,
  NEXT,
  CURRENT_SEASON_GENIUS_COMP_ID,
} from '../../constants/misc';
import { IChannel, ITeam } from '../../types';
import { useGetTeamsQuery } from '../../store/apis/genius';
import AppListItem from '../AppListItem';
import TeamSelectList from '../TeamSelectList';

export interface SecondPageProps {
  setStep?: (value: number) => void;
}

const SecondPage: React.FC<SecondPageProps> = ({ setStep }) => {
  const { values, setFieldValue } = useFormikContext<IChannel>();
  const {
    data = [],
    isUninitialized,
    isLoading,
  } = useGetTeamsQuery(CURRENT_SEASON_GENIUS_COMP_ID);

  const teams = data
    .filter((team) => !values.teams.some((t) => t.teamId === team.teamId))
    .map((team) => {
      const newTeam: ITeam = {
        teamId: team.teamId,
        data: [],
        name: team.teamName,
        image: team.images?.logo?.T1?.url,
        players: [],
      };

      return newTeam;
    });

  const addTeam = useCallback(
    (team: ITeam) => {
      const teamsNew = [...values.teams, team];

      setFieldValue('teams', teamsNew);
    },
    [values, setFieldValue]
  );

  const removeTeam = useCallback(
    (team: ITeam) => {
      const teamsNew = values.teams.filter((t) => t.teamId !== team.teamId);

      setFieldValue('teams', teamsNew);
    },
    [values, setFieldValue]
  );

  return (
    <AppListItem title="Select your teams">
      <Box>
        <Box marginBottom={8} maxWidth={360}>
          <Typography>Select up to three teams.</Typography>
        </Box>
        <Box marginY={8}>
          <Typography variant="h3">
            Your teams ({values.teams.length}/3 selected)
          </Typography>
        </Box>
        <Box marginY={8} maxWidth={480}>
          <TeamSelectList
            options={values.teams}
            setValue={(team) => removeTeam(team)}
          />
        </Box>
        <Box marginY={8}>
          <Typography variant="h3">Add another team</Typography>
        </Box>
        <Box marginY={8} maxWidth={960}>
          {isUninitialized || isLoading ? (
            <CircularProgress />
          ) : (
            <TeamSelectList
              options={teams.filter(
                (team) => !values.teams.some((t) => t.teamId === team.teamId)
              )}
              disabled={values.teams.length > 2}
              setValue={(team) => addTeam(team)}
              add
              wrap
            />
          )}
        </Box>
        <Box marginY={16} maxWidth={360}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                size="large"
                onClick={() => setStep && setStep(0)}
                startIcon={<KeyboardArrowLeftSharp />}
              >
                {BACK}
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={() => setStep && setStep(2)}
                disabled={values.teams.length < 1}
                endIcon={<KeyboardArrowRightSharp />}
              >
                {NEXT}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </AppListItem>
  );
};

export default SecondPage;

import { ProximaBold, ProximaRegular } from './fonts';

const overrides = {
  MuiCssBaseline: {
    '@global': {
      html: {
        WebkitFontSmoothing: 'auto',
        height: '100%',
      },
      body: {
        height: '100%',
      },
      '#root': {
        height: '100%',
      },
      '@font-face': [ProximaRegular, ProximaBold],
      '::-webkit-scrollbar': {
        width: '10px',
      },
      '::-webkit-scrollbar-track': {
        background: 'black',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#ee7623',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#ee7623',
      },
    },
  },
  MuiTabPanel: {
    root: {
      padding: 0,
    },
  },
  MuiAlert: {
    root: {
      borderRadius: 0,
      fontSize: '0.9rem',
    },
  },
  MuiTooltip: {
    tooltip: {
      fontSize: '0.75rem',
    },
  },
  MuiButton: {
    root: {
      borderRadius: 0,
    },
    iconSizeSmall: {
      marginRight: 4,
    },
    sizeSmall: {
      paddingLeft: 4,
      paddingRight: 4,
      minWidth: 0,
    },
    textSizeSmall: {
      fontWeight: 400,
      fontSize: '0.75rem',
    },
    sizeLarge: {
      fontWeight: 700,
      fontSize: '0.8rem',
      color: 'white',
      lineHeight: 1.5,
      'text-transform': 'uppercase',
    },
    outlinedPrimary: {
      borderColor: 'rgba(238, 118, 35, 1)',
    },
    outlinedSizeLarge: {
      paddingTop: 11,
      paddingBottom: 11,
    },
    containedSizeLarge: {
      paddingTop: 12,
      paddingBottom: 12,
    },
  },
  MuiCheckbox: {
    root: {
      borderRadius: 0,
    },
  },
  MuiListItem: {
    root: {
      borderRadius: 0,
    },
  },
  MuiDivider: {
    inset: {
      marginLeft: 16,
    },
  },
  MuiCardActions: {
    root: {
      padding: 0,
    },
  },
  MuiCardContent: {
    root: {
      padding: 0,
    },
  },
};

export default overrides;

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Typography, Box } from '@material-ui/core';

const NoIdPage: React.FC = () => (
  <>
    <Helmet title="National Basketball League | NBL | No ID" />
    <Box paddingY={16}>
      <Typography variant="h1" align="center">
        Not Authenticated
      </Typography>
    </Box>
  </>
);

export default NoIdPage;

export const LEADERBOARD_IFRAME =
  'https://ads.nbl.com.au/nbl/leaderboard/one/720/90';
export const LEADERBOARD_IFRAME_MOBILE =
  'https://ads.nbl.com.au/nbl/mobile-leaderboard/one/320/50';
export const ADVERT_ONE = 'leaderboard one';
export const ADVERT_TWO = 'leaderboard two';
export const ADVERT_THREE = 'leaderboard three';
export const SEASON_TXT = 'season';
export const PLAYERS_TXT = 'players';
export const ACTIONS_TXT = 'actions';
export const ROUNDS_TXT = 'rounds';
export const YOUR_CHANNEL_NAME = 'Your channel name';
export const EMAIL_HIGHLIGHTS =
  'Would you like highlights and video emailed to you when new content becomes available?';
export const FULL_GAME_REPLAYS = 'Full Game Replays';
export const CONDENSED_GAME_HIGHLIGHTS = 'Condensed Game Replay (10-12 mins)';
export const GAME_HIGHLIGHTS = 'Game Highlights';
export const PLAYER_HIGHLIGHTS = 'Player Highlights';
export const PRESS_CONFERENCE = 'Press Conference';
export const CHOOSE_HERE_IF =
  'To set up your personalised channel, configure your preferences here.';
export const BUILD_YOUR_OWN = 'Build your own channel';
export const BUILD_YOUR_NBL_CHANNEL = 'Build your NBL channel';
export const LETS_GET_STARTED = `Let's get started`;
export const SELECT_YOUR_TEAMS = 'Select your teams';
export const BACK = 'BACK';
export const NEXT = 'NEXT';
export const EMAIL = 'Email';
export const NAME = 'Name';
export const GENERATE_CHANNEL = 'GENERATE CHANNEL';
export const HERE_YOU_CAN_SELECT =
  'Here you can select the type of content you want to see on your channel';
export const ENTER_A_NAME_FOR_YOUR_CHANNEL =
  'Enter a name for your channel and select your teams.';
export const LINK_COPIED = 'Link copied!';
export const SHARE = 'Share';
export const HERE_YOU_CAN_SELECT_IF_YOU_WANT_TO_FOLLOW_A_CERTAIN_PLAYER =
  'Here you can select if you want to follow a certain player';
export const NO_RESULTS_FOUND = 'No results found, highlight not created';
export const GENERATE_HIGHLIGHT = 'Generate Highlight';
export const CREATE_HIGHLIGHT = 'Create Highlight';
export const MY_CHANNEL_HISTORY = 'My Channel History';
export const SYNC_TO_CALENDER = 'Sync to Calender';
export const MY_CHANNEL = 'My Channel';
export const CHANNEL_SETTINGS = 'Channel Settings';
export const CHANNEL_PREFERENCES = 'Channel preferences';
export const POPULAR_CHANNELS = 'Popular Channels';
export const NOW_SHOWING = 'Now Showing';
export const MY_FOLLOWERS = 'My Followers';
export const FOLLOWERS = 'Followers';
export const NO_RESULTS_TO_DISPLAY = 'No results to display';
export const MATCH_DATA_IS_UPDATED =
  'Match data is updated post game. Please allow 24 hours for new games to appear.';
export const LOADING_TEXT = 'Loading...';
export const CURRENT_SEASON_GENIUS_COMP_ID = 35847;
export const CURRENT_SEASON_NUMBER = '2023-2024';

import React from 'react';
import { Box, Hidden } from '@material-ui/core';

import {
  LEADERBOARD_IFRAME,
  LEADERBOARD_IFRAME_MOBILE,
} from '../../constants/misc';

export interface AdvertProps {
  title: string;
}

const Advert: React.FC<AdvertProps> = ({ title }) => (
  <Box paddingY={8}>
    <Hidden smDown>
      <Box maxWidth={728} height={90} width="100%" margin="auto">
        <iframe
          src={LEADERBOARD_IFRAME}
          width="728"
          height="90"
          title={title}
          style={{ border: 0 }}
        />
      </Box>
    </Hidden>
    <Hidden mdUp>
      <Box maxWidth={320} height={50} width="100%" margin="auto">
        <iframe
          src={LEADERBOARD_IFRAME_MOBILE}
          width="320"
          height="50"
          title={title}
          style={{ border: 0 }}
        />
      </Box>
    </Hidden>
  </Box>
);

export default Advert;

import React from 'react';
import { useSelector } from 'react-redux';
import { Hidden, Divider } from '@material-ui/core';

import { ADVERT_ONE, ADVERT_TWO, ADVERT_THREE } from '../constants/misc';
import { selectUserId } from '../store/slices/user';
import Layout from '../layouts/ContainerLayout';
import Advert from '../components/Advert';
import Channel from '../components/Channel';
import History from '../components/History';

const ChannelPage: React.FC = () => {
  const userId = useSelector(selectUserId);

  return (
    <Layout>
      <Hidden smDown>
        <Advert title={ADVERT_ONE} />
      </Hidden>
      <Channel userId={userId} />
      <Advert title={ADVERT_TWO} />
      <Divider />
      <History userId={userId} />
      <Advert title={ADVERT_THREE} />
    </Layout>
  );
};

export default ChannelPage;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  TeamPayloadType,
  SeasonType,
  MatchPayloadType,
  PlayerPayloadType,
  RoundsPayloadType,
} from '../../types';

export const prepareHeaders = (headers: Headers) => {
  headers.set('x-api-key', process.env.REACT_APP_GENIUS_API_KEY);

  return headers;
};

// Hooks for genius API.
// https://redux-toolkit.js.org/rtk-query/overview

const genius = createApi({
  reducerPath: 'genius',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_GENIUS_ENDPOINT}/v1/basketball`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getTeams: builder.query<TeamPayloadType[], number>({
      query: (competitionId) => `competitions/${competitionId}/teams?limit=499`,
      transformResponse: (response: {
        response: { data: TeamPayloadType[] };
      }) => response.response?.data,
    }),
    getPlayers: builder.query<
      PlayerPayloadType[],
      { competitionId: number; teamId: number }
    >({
      query: ({ competitionId, teamId }) =>
        `competitions/${competitionId}/teams/${teamId}/persons?limit=499`,
      transformResponse: (response: {
        response: { data: PlayerPayloadType[] };
      }) => response.response?.data || [],
    }),
    getParticipants: builder.query<
      PlayerPayloadType[],
      {
        competitionId: number;
        matchId: number;
        teamId: number;
      }
    >({
      query: ({ competitionId, matchId, teamId }) =>
        `competitions/${competitionId}/matches/${matchId}/teams/${teamId}/persons?limit=499&isPlayer=1`,
      transformResponse: (response: {
        response: { data: PlayerPayloadType[] };
      }) => {
        if (Array.isArray(response.response?.data)) {
          return response.response.data.sort((a, b) =>
            a.firstName.localeCompare(b.firstName)
          );
        }

        return [];
      },
    }),
    getMatches: builder.query<
      MatchPayloadType[],
      {
        competitionId: number;
        roundNumber?: number;
        matchType?: string;
      }
    >({
      query: ({ competitionId, roundNumber, matchType }) =>
        `competitions/${competitionId}/matches?limit=499${
          roundNumber ? `&roundNumber=${roundNumber}` : ''
        }${matchType ? `&matchType=${matchType}` : ''}`,
      transformResponse: (response: {
        response: { data: MatchPayloadType[] };
      }) => {
        if (Array.isArray(response.response?.data)) {
          return response.response.data.sort(
            (a, b) =>
              new Date(a.matchTime).getTime() - new Date(b.matchTime).getTime()
          );
        }

        return [];
      },
    }),
    getRounds: builder.query<RoundsPayloadType[], number>({
      query: (id) => `competitions/${id}/rounds?limit=499`,
      transformResponse: (response: {
        response: { data: RoundsPayloadType[] };
      }) => response.response?.data,
    }),
    getRoundsTotal: builder.query<number, number>({
      query: (competitionId) =>
        `competitions/${competitionId}/rounds?limit=499`,
      transformResponse: (response: {
        response: { meta: { count: number } };
      }) => response.response?.meta?.count || 0,
    }),
    getCompetition: builder.query<SeasonType, number | undefined>({
      query: (season = new Date().getFullYear() - 1) =>
        `competitions?season=${season}-${season + 1}&limit=499`,
      transformResponse: (response: { response: { data: SeasonType[] } }) => {
        const competitions = response.response?.data;

        if (
          Array.isArray(competitions) &&
          competitions.some(({ competitionName }) => competitionName === 'NBL')
        ) {
          const competition = competitions.find(
            ({ competitionName }) => competitionName === 'NBL'
          );

          return competition;
        }

        return;
      },
    }),
  }),
});

export const {
  useGetPlayersQuery,
  useGetRoundsQuery,
  useGetRoundsTotalQuery,
  useGetTeamsQuery,
  useGetCompetitionQuery,
  useGetMatchesQuery,
  useGetParticipantsQuery,
} = genius;

export default genius;

import React, { useState, useEffect } from 'react';
import {
  Button,
  ButtonProps,
  CircularProgress,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { AddCircleOutlineSharp } from '@material-ui/icons';

import { useUpdateFollowersMutation } from '../../store/apis/liniusMiddleware';

const useButtonStyles = makeStyles<Theme, { dark: boolean }>((theme) => ({
  root: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: (props) => (props.dark ? 'rgba(255, 255, 255, 0.5)' : 'inherit'),
    borderColor: (props) =>
      props.dark ? 'rgba(255, 255, 255, 0.5)' : theme.palette.primary.main,
  },
}));

export interface FollowButtonProps extends ButtonProps {
  userId: string;
  channelId: string;
  following: boolean;
}

const FollowButton: React.FC<FollowButtonProps> = ({
  userId,
  channelId,
  following,
  ...rest
}) => {
  const buttonStyles = useButtonStyles({ dark: following });
  const [isFollowing, setFollowing] = useState(following);
  const [updateFollowers, { isLoading }] = useUpdateFollowersMutation();
  const loading = isLoading || isFollowing !== following;

  useEffect(() => {
    setFollowing(following);
  }, [following]);

  const onClick = () => {
    setFollowing(!following);
    updateFollowers({
      user_id: channelId,
      follow: !following,
      follower_id: userId,
    });
  };

  return (
    <Button
      {...rest}
      classes={buttonStyles}
      disabled={loading}
      endIcon={
        loading ? (
          <CircularProgress size={22} thickness={6} />
        ) : !isFollowing ? (
          <AddCircleOutlineSharp color="primary" />
        ) : null
      }
      onClick={onClick}
    >
      {loading ? 'LOADING' : isFollowing ? 'UNFOLLOW' : 'FOLLOW'}
    </Button>
  );
};

export default FollowButton;

import { useState, useEffect } from "react";
import { VideoJsPlayer } from "video.js";

const useVideoPlayerForcePlay = (vjsPlayer: VideoJsPlayer): VideoJsPlayer => {
  const [isPlayerDefined, setPlayerDefined] = useState<boolean>(false);
  const [retryTimer, setRetryTimer] = useState<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    const forcePlay = () => {
      const maxAttempts = 10;

      let playPromise: Promise<void>;
      let attempt = 0;
      let pending = true;

      const tryForcePlay = () => {
        if (attempt > 0 && attempt < vjsPlayer.duration()) {
          vjsPlayer.currentTime(attempt + 0.5);
        }

        playPromise = vjsPlayer.play();

        attempt++;

        if (playPromise && attempt < maxAttempts) {
          playPromise
            .then(() => {
              pending = false;
            })
            .catch(() => {});

          setRetryTimer(
            setTimeout(() => {
              if (pending) {
                tryForcePlay();
              }
            }, 1000)
          );
        }
      };

      tryForcePlay();
    };

    if (vjsPlayer && !isPlayerDefined) {
      vjsPlayer.on("play", () => {
        forcePlay();
      });

      setPlayerDefined(true);
    }

    if (!vjsPlayer && isPlayerDefined) {
      setPlayerDefined(false);
    }
  }, [vjsPlayer, isPlayerDefined, setPlayerDefined]);

  useEffect(() => {
    return () => {
      clearTimeout(retryTimer);
    };
  }, [retryTimer]);

  return vjsPlayer;
};

export { useVideoPlayerForcePlay };
